import HeaderTop from "../Components/Header/HeaderTop";
import MainBanner from "../Components/MainBanner";
import ServicesFull from "../Components/ServiceFull";
import { useEffect } from "react";

function Services({toggleDownloadModalState, onChangeLogin, onChangeRegistration}) {

    useEffect(() => {
        window.scrollTo({top: 0, behavior: 'smooth'});
    }, [])

    return ( 
        <>
            <HeaderTop viewType={'map'} onChangeLogin={onChangeLogin} onChangeRegistration={onChangeRegistration}/>
            <ServicesFull onChangeRegistration={onChangeRegistration} toggleDownloadModalState={toggleDownloadModalState} />
            <MainBanner onChangeRegistration={onChangeRegistration}/>
        </>
     );
}

export default Services;