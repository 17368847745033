import SectionTitle from '../SectionTitle';
import styles from './styles.module.scss'
import { useTranslation, Trans } from "react-i18next";
import useContacts from '../../Hooks/useGetContacts'
import { useEffect, useState } from 'react';
import useSendContactRequest from '../../Hooks/useSendContactRequest'
import { ColorRing } from 'react-loader-spinner'
import toast from 'react-hot-toast';
import PhoneInput from 'react-phone-input-2';
import constants from "../../Hooks/constants";

function ContactSection() {
    const { t, i18n } = useTranslation();
    const [contacts, getContacts] = useContacts();
    const [response, sendContactRequest] = useSendContactRequest();

    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        getContacts(i18n.language)
    }, [])

    useEffect(() => {
        if(response !== null) {
            setLoading(false)
            if(response.success) {
                toast.success(t('Success'))
                setName('');
                setPhone('');
                setEmail('');
                setMessage('');
            } else {
                toast.error(response.message)
            }
        }
    }, [response])

    const onSendContact = () => {
        if(name === '') {
            toast.error(t('Please fill name field.'))
        } else if(phone === '') {
            toast.error(t('Please fill phone number field.'))
        } else if(email === '') {
            toast.error(t('Please fill e-mail field.'))
        } else if(message === '') {
            toast.error(t('Please fill message field.'))
        } else {
            setLoading(true)
            sendContactRequest(name, phone, email, message, i18n.language);

        }
    }

    return (
        <section className={styles.section}>
            <div className="container">
                <SectionTitle text={'Contacts'} />
                <div className={styles.content}>
                    <div className={styles.info}>
                        <h3 className={styles.title}><Trans>Get in touch</Trans></h3>
                        {
                            contacts.map((contact, index, array) => {
                                return (
                                    <div key={index}>
                                        <h6 className={styles.smallTitle}>{contact.name}</h6>
                                        <div className={styles.infoItem}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                                                <path d="M8.00065 4.53299C8.44268 4.53299 8.8666 4.70858 9.17916 5.02114C9.49172 5.33371 9.66732 5.75763 9.66732 6.19966C9.66732 6.41853 9.62421 6.63525 9.54045 6.83746C9.45669 7.03967 9.33393 7.2234 9.17916 7.37817C9.0244 7.53293 8.84067 7.6557 8.63846 7.73946C8.43625 7.82321 8.21952 7.86632 8.00065 7.86632C7.55862 7.86632 7.1347 7.69073 6.82214 7.37817C6.50958 7.06561 6.33398 6.64168 6.33398 6.19966C6.33398 5.75763 6.50958 5.33371 6.82214 5.02114C7.1347 4.70858 7.55862 4.53299 8.00065 4.53299ZM8.00065 1.53299C9.23833 1.53299 10.4253 2.02465 11.3005 2.89982C12.1757 3.77499 12.6673 4.96198 12.6673 6.19966C12.6673 9.69966 8.00065 14.8663 8.00065 14.8663C8.00065 14.8663 3.33398 9.69966 3.33398 6.19966C3.33398 4.96198 3.82565 3.77499 4.70082 2.89982C5.57599 2.02465 6.76297 1.53299 8.00065 1.53299ZM8.00065 2.86632C7.1166 2.86632 6.26875 3.21751 5.64363 3.84263C5.01851 4.46775 4.66732 5.3156 4.66732 6.19966C4.66732 6.86632 4.66732 8.19966 8.00065 12.673C11.334 8.19966 11.334 6.86632 11.334 6.19966C11.334 5.3156 10.9828 4.46775 10.3577 3.84263C9.73255 3.21751 8.88471 2.86632 8.00065 2.86632Z" fill="#3A3A3A" />
                                            </svg>
                                            <p className={styles.textItem}>{contact.address}</p>
                                        </div>
                                        <div className={styles.infoItem}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                                                <g clipPath="url(#clip0_123_227)">
                                                    <path d="M11.7195 0.199646H4.28059C3.88494 0.199646 3.56278 0.521519 3.5625 0.918036V15.4821C3.5628 15.8772 3.88494 16.1988 4.28059 16.1997H11.7201C12.116 16.1988 12.4373 15.8769 12.4373 15.4816V0.918036C12.4373 0.521519 12.1157 0.199646 11.7195 0.199646ZM8.79165 14.4573C8.79079 14.8939 8.43493 15.2497 8.00122 15.25C7.56491 15.2494 7.20964 14.8939 7.20934 14.4596C7.20993 14.0233 7.56374 13.6689 7.99949 13.668C8.43578 13.6692 8.79079 14.0236 8.79165 14.4573ZM11.6369 12.0079C11.637 12.5602 11.1892 13.008 10.6369 13.008H5.36339C4.81111 13.008 4.36339 12.5603 4.36339 12.008V2.72937C4.36339 2.17709 4.81111 1.72937 5.36339 1.72937H10.6365C11.1887 1.72937 11.6364 2.17706 11.6365 2.72932L11.6369 12.0079Z" fill="#3A3A3A" />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_123_227">
                                                        <rect width="16" height="16" fill="white" transform="translate(0 0.199646)" />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                            <p className={styles.textItem}>{contact.phone1}</p>
                                            {contact.phone2 !== '' && (
                                                <p className={styles.textItem}>{contact.phone2}</p>
                                            )}
                                        </div>
                                        <div className={styles.infoItem}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                                                <path d="M14.6673 4.19964C14.6673 3.4663 14.0673 2.8663 13.334 2.8663H2.66732C1.93398 2.8663 1.33398 3.4663 1.33398 4.19964V12.1996C1.33398 12.933 1.93398 13.533 2.66732 13.533H13.334C14.0673 13.533 14.6673 12.933 14.6673 12.1996V4.19964ZM13.334 4.19964L8.00065 7.53297L2.66732 4.19964H13.334ZM13.334 12.1996H2.66732V5.53297L8.00065 8.8663L13.334 5.53297V12.1996Z" fill="#3A3A3A" />
                                            </svg>
                                            <p className={styles.textItem}><a href={`mailto:${contact.link1}`}>{contact.link1}</a></p>
                                            {contact.link2 !== '' && (
                                                <p className={styles.textItem}><a href={`mailto:${contact.link2}`}>{contact.link2}</a></p>
                                            )}
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className={styles.form}>
                        <h2 className={styles.title}><Trans>Contact form</Trans></h2>
                        <div className={styles.inputWrap}>
                            <p className={styles.inputTitle}><Trans>Full name</Trans></p>
                            <input type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder={t('Enter your full name')} />
                        </div>
                        <div className={styles.inputs}>
                            <div className={styles.inputWrap1} style={{flexGrow: 1}}>
                                <p className={styles.inputTitle}><Trans>Phone</Trans></p>
                                {/* <button className={styles.country}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <path d="M10 20C15.5229 20 20 15.5229 20 10C20 4.47715 15.5229 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5229 4.47715 20 10 20Z" fill="#F0F0F0" />
                                        <path d="M19.378 13.4783C19.78 12.395 20 11.2232 20 10C20 8.77683 19.78 7.6051 19.378 6.52179H0.621993C0.220039 7.6051 0 8.77683 0 10C0 11.2232 0.220039 12.395 0.621993 13.4783L10 14.3479L19.378 13.4783Z" fill="#0052B4" />
                                        <path d="M10.0001 20C14.2998 20 17.9652 17.2863 19.378 13.4783H0.62207C2.035 17.2863 5.70042 20 10.0001 20Z" fill="#D80027" />
                                    </svg>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                        <path d="M10 4L6 8L2 4" stroke="#899AA4" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </button>
                                <span className={styles.line} /> */}
                                {/* <input className={styles.phoneInput} value={phone} onChange={(e) => setPhone(e.target.value)} type="text" placeholder='+90 xxx yyy zzz' /> */}
                                <PhoneInput
                                    containerStyle={{width: '100%!important'}}
                                    inputStyle={{borderRadius: 100, paddingLeft: 50}}
                                    buttonStyle={{borderRadius: 100, border: 'none', background: 'transparent', margin: 5}}
                                    country={'tr'}
                                    onlyCountries={constants.allowedCountries}
                                    value={phone}
                                    placeholder={t("Enter your phone number")}
                                    onChange={phone => setPhone(phone)}
                                />
                            </div>
                            <div className={styles.inputWrap} style={{flexGrow: 1}}>
                                <p className={styles.inputTitle}><Trans>E-mail</Trans></p>
                                <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} placeholder={t('Enter your E-mail')} />
                            </div>
                        </div>
                        <div className={styles.inputWrap}>
                            <p className={styles.inputTitle}><Trans>Message</Trans></p>
                            <textarea value={message} onChange={(e) => setMessage(e.target.value)} placeholder={t('Enter your message or question')}/>
                        </div>
                        <div className={styles.inputWrap}>
                            <button onClick={onSendContact} className={styles.submit}>
                                <Trans>Send message</Trans>
                                {loading && (
                                    <ColorRing
                                        visible={true}
                                        height="30"
                                        width="30"
                                        ariaLabel="blocks-loading"
                                        wrapperStyle={{}}
                                        wrapperClass="blocks-wrapper"
                                        colors={['#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF']}
                                    />
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ContactSection;