export default {
    "More than 800 services are<br/><2>waiting for you.</2>": "800'den fazla hizmet<br/><2>sizi bekliyor.</2>",
    "Nearby": "Yakın",
    "Find": "Bul",
    "Your safety and trust are our top priorities.": "Güvenliğiniz ve itimadınız bizim en önemli önceliklerimizdir.",
    "We help you make informed decisions, provide a rating and review system based on the experiences of other users.": "Diğer kullanıcıların deneyimlerine dayanan bir derecelendirme ve inceleme sistemi sağlayarak bilinçli kararlar vermenize yardımcı oluyoruz.",
    "With BURABAK, you can experience the comfort of knowing that your service needs are in competent hands.": "BURABAK ile hizmet ihtiyaçlarınızın uzman ellerde olduğundan emin olabilirsiniz.",
    "At BURBAK, we are passionate about ": "BURABAK olarak, bireylerin ve",
    "transforming the way individuals and businesses access services. Our mission is to create a more sustainable and connected world by promoting resource sharing and community building.": "işletmelerin hizmetlere erişim biçimlerini dönüştürme konusunda tutkuluyuz. Misyonumuz, hizmet paylaşımını ve topluluk oluşturmayı kolaylaştırarak daha sürdürülebilir ve bağlantılı bir dünya yaratmaktır.",
    "We believe everyone should have easy access to the resources and expertise they need, regardless of traditional barriers.": "Geleneksel engellerden bağımsız olarak herkesin ihtiyaç duyduğu kaynaklara ve bilgiye kolayca erişebilmesi gerektiğine inanıyoruz.",
    "With BURABAK, you can access a wide variety of services to suit your needs. If you need transportation, repair and maintenance, personal care or any other service, our platform will help you.": "BURABAK ile ihtiyaçlarınızı karşılayacak geniş bir hizmet yelpazesine erişebilirsiniz. Ulaşım, onarım ve bakım, kişisel bakım veya başka bir hizmete ihtiyacınız olsun, platformumuz size yardımcı olabilir.",
    "<0>Revolutionizing Service-Sharing</0><br/>for a Connected World.": "<0>Bağlantılı bir dünyada</0><br/>hizmette bir devrim.",
    "about us by numbers": "rakamlarla hakkimizda",
    "Professionals": "Profesyoneller",
    "Services": "Hizmetler",
    "Customers": "Müşteriler",
    "Simplicity": "Basitlik",
    "Our user-friendly platform allows you to connect with service providers in your area with just a few clicks.": "Kullanıcı dostu platformumuz, sadece birkaç tıklamayla bölgenizdeki veya ötesindeki hizmet sağlayıcılarla bağlantı kurmanıza olanak tanır.",
    "Costs": "Maliyetler",
    "We understand that cost is important, which is why our service providers offer competitive prices.": "Maliyetin önemli olduğunun farkındayız, bu nedenle hizmet sağlayıcılarımız rekabetçi fiyatlar sunmaktadır.",
    "Location": "Konum",
    "With our location-based search, it's easy to find someone nearby for help.": "Konum aramasıyla, yardım almak için yakınınızdaki birini kolayca bulabilirsiniz.",
    "<0>User  </0>Agreement.": "<0>Kullanıcı  </0>Anlaşma.",
    "Why we are the Best": "Neden en iyi biziz",
    "We are constantly improving our platform in line with your feedback and suggestions. Your contributions are invaluable in improving our services and making your experience even better.": "Geri bildirimleriniz ve önerileriniz doğrultusunda platformumuzu sürekli olarak geliştiriyoruz. Görüşleriniz, hizmetlerimizi ve hizmet kalitemizi iyileştirmek için çok değerli.",
    "Our commitment to innovation drives us to explore new possibilities and bring you the best possible service sharing experience.": "Yeniliğe olan bağlılığımız bizi yeni fırsatları keşfetmeye ve size mümkün olan en iyi ortak hizmet deneyimini sunmaya itiyor.",
    "Get in touch": "Bize ulaşın",
    "Contact form": "İletişim formu",
    "Full name": "Adınız",
    "Enter your name": "Adınızı giriniz",
    "Enter your full name": "Adınızı girin",
    "Phone": "Telefon",
    "E-mail": "E-posta",
    "Enter your E-mail": "E-postanızı girin",
    "Message": "Mesaj",
    "Enter your message or question": "Mesajınızı veya sorunuzu girin",
    "Send message": "Mesaj gönder",
    "INFO": "BILGI",
    "About us": "Hakkımızda",
    "Contact us": "Bize ulaşın",
    "How it work?": "Nasıl çalışıyor?",
    "ADDITIONAL": "EK",
    "Support": "Destek",
    "Privacy Policy": "Gizlilik Politikası",
    "FAQ": "SSS",
    "User Agreement": "Kullanıcı Sözleşmesi",
    "TO REACH US": "BIZE ULAŞIN",
    "All Rights Reserved.": "Tüm Hakları Saklıdır.",
    "Sign In": "Oturum Aç.",
    "Sign Up": "Kaydolun.",
    "Sign Out": "Oturumu Kapat",
    "How does this work": "Bu nasıl çalışıyor",
    "About": "Hakkımızda",
    "Login": "Giriş yap",
    "Join us": "Kaydol",
    "What do you want?": "Ne arıyorsun?",
    "Where?": "Nerede?",
    "Write your needs in a few words": "İhtiyaçlarınızı birkaç kelimeyle yazın",
    "See experts on demand": "Talep üzerine uzmanları görün.",
    "Compare prices and choose": "Fiyatları karşılaştırın ve seçin",
    "Join BURBAK today": "BURABAK'a bugün katılın.",
    "Join BURABAK today and embark on a transformative journey. Experience the convenience, affordability, and sense of connection that service sharing brings. Together we can shape a brighter future where resources are shared, communities thrive and sustainable living is at hand.": "BURABAK'a bugün katılın ve bir yolculuğa çıkın. Paylaşımın getirdiği kolaylığı, erişilebilirliği ve bağlantı hissini deneyimleyin. Birlikte, kaynakların paylaşıldığı, toplulukların geliştiği ve yaşamların sürdürülebilir olduğu parlak bir geleceği şekillendirebiliriz.",
    "Join": "Katılın",
    "Free and without obligation.": "Ücretsiz ve yükümlülük olmadan.",
    "With BURABAK you will find the best experts in your field, whatever your needs.": "BURABAK ile ihtiyaçlarınız ne olursa olsun alanınızdaki en iyi uzmanları bulacaksınız.",
    "Register": "Kaydol",
    "More suitable in app": "Uygulamada daha fazlası",
    "Download": "İndir",
    "Enter your phone number": "Telefon numaranızı girin",
    "Password": "Şifre",
    "Enter your password": "Şifrenizi girin.",
    "I forgot password": "Şifremi unuttum",
    "Hide": "Gizle",
    "Show": "Göster",
    "More": "Daha",
    "Popular Categories": "Popüler Kategoriler",
    "Are you professional?": "Profesyonel misiniz?",
    "BURABAK Your partner for finding new customers online": "BURABAK Yeni müşteriler bulmak için çevrimiçi ortağınız",
    "Details": "Detaylar",
    "An expert for every need": "Her ihtiyaç için bir uzman",
    "By clicking Continue, I accept the <1>Membership Terms</1>.": "Devam'a tıklayarak <1>Üyelik Koşullarını</1>kabul ediyorum.",
    "Send code": "Kod gönder",
    "An SMS with a confirmation code has been sent to your phone number. Enter it in the field below and click 'Continue'": "Telefon numaranıza onay kodu içeren bir SMS gönderildi. Aşağıdaki alana girin ve 'Devam'a tıklayın",
    "Send SMS": "SMS Gönder",
    "Surname": "Soyadı",
    "Man": "Erkek",
    "Woman" : "Kadın",
    "Next": "Devam",
    "<0>Privacy  </0>Policy.": "<0>Gizlilik   </0>Politikası.",
    "<0>HOW IT  </0><br/>Work?": "<0>BU NASIL  </0><br/>ÇALIŞIYOR?",
    "welcome_professional": "Profesyonel misiniz? Yarı zamanlı bir işe mi ihtiyacınız var? İş mi arıyorsunuz? O zaman bize katılın. Kayıt olun ve profilinizi doldurun. Sizin takdirinize bağlı olarak, yakın veya başka bir şehirde iş için siparişler veya talepler görünecektir. Yanıt verebilir, pazarlık yapabilir ve işe başlayabilirsiniz. Sadece aylık veya yıllık bir abonelik ücreti ödeyin ve istediğiniz zaman çalışın veya evde oturup sipariş bekleyin.",
    "welcome_customer": "Bilinçli kararlar vermenize yardımcı oluyor ve diğer kullanıcıların deneyimlerine dayanan bir derecelendirme ve geri bildirim sistemi sağlıyoruz. BURABAK ile hizmetinizin ehil ellerde olduğundan emin olabilirsiniz. Sadece şartlar ve koşullarla birlikte bir talep bırakın ve en yakın profesyonel yanıt verecektir. Bu hizmet için herhangi bir ücret talep etmiyoruz ve uzman ile anlaşmanızı bizzat kendiniz yapabilirsiniz.",
    "click to pay": "tıkla ve öde",
    "include all system no extra<br/> payment needed": "'Hepsi bir arada' sistem ek ücret gerektirmez.",
    "Download our mobile application": "Mobil uygulamamızı indirin",
    "Scan the QR code and download our mobile app": "QR kodunu tarayın ve mobil uygulamamızı indirin",
    "Sneak peek1": "Projemizin ruhuna bir göz atın.",
    "Sneak peek2": "Özellikler kendilerini göstermeye hazır ve yakında web sitesini süsleyecek.",
    "Sneak peek5": "Dalış yapın, işbirliği yapın, güncel kalın ve fikirlerinizi paylaşın.",
    "Sneak peek3": "sitemizden bize göz atın.",
    "Sneak peek4": "Web",
    "OK": "TAMAM",
    "Create an account here": "Burada bir hesap oluşturun",
    "Invalid Code!": "Geçersiz Kod!",
    "Please Input Phone Number": "Lütfen Telefon Numaranızı Girin",
    "Please Input Password": "Lütfen Şifre Girin.",
    "Resend in": "Tekrar Gönder",
    "Password Length Must Be Greater Than 8.": "Şifre Uzunluğu 8'den Büyük Olmalıdır.",
    "Please Check Your Network Status": "Lütfen ağ durumunuzu kontrol edin.",
    "User Already Exists": "Kullanıcı Zaten Var",
    "Your phone number": "Telefon numaranız",
    "One more time": "Bir kez daha",
    "New Password": "Yeni Şifre",
    "Go": "Git",
    "User Not Exists": "Kullanıcı Mevcut Değil",
    "Sign Up Success": "Kayıt işlemi başarılı",
    "Please confirm your password.": "Lütfen şifrenizi onaylayın.",
    "Success": "Başarı",
    "By clicking Continue, I accept the <1>User Agreement</1> and <3>Privacy Policy</3>.": "Devam'a tıklayarak <1>Kullanıcı Sözleşmesi</1> ve <3>Gizlilik Politikası</3>'nı kabul ediyorum..",
    "Please Input Full Name": "Lütfen Adınızı Giriniz",
    "Please Input Sur Name": "Lütfen Soyadınızı Giriniz",
    "Please Input Password One More Time": "Lütfen Şifrenizi Bir Kez Daha Girin",
    "House Cleaning": "Ev Temizliği",
    "Office Cleaning": "Ofis Temizliği",
    "After renovation": "Tadilattan sonra",
    "Deep cleaning": "Derin temizlik",
    "Cleaning": "Temizlik",
    "Ceremony": "Tören",
    "Wedding halls": "Düğün salonları",
    "Musicans": "Müzisyenler",
    "Mourning halls": "Matem salonları",
    "Wedding cars": "Düğün arabaları",
    "Legal": "Hukuk",
    "Legal Advisor":"Hukuk Danışmanı",
    "Corporate lawyer":"Kurumsal Avukat",
    "Real Estate":"Emlak",
    "Accounting":"Muhasebe",
    "Financial Advisor":"Y.Mali Müşavir",
    "Accountant":"Mali Müşavir",
    "Stock advisor":"Borsa danışmanı",
    "Health":"Sağlık",
    "Nurses":"Hemşire",
    "Pharmacy":"Eczane",
    "Emergency":"Ambulans",
    "Clinics":"Klinikler",
    "Sport":"Spor",
    "GYM":"Spor salonu",
    "Body-building":"Vücut geliştirme",
    "Boxing":"Boks",
    "Swiming pool":"Yüzme",
    "Transport":"Nakliye",
    "Home to Home":"Evden Eve",
    'Office to Office':'Ofisten Ofise',
    'In Country':'Ülke İçinde',
    'Out of Country':'Ülke Dışında',
    'Private lesson':'Özel ders',
    'Maths':'Matematik',
    'English':'İngilizce',
    'Germany':'Almanca',
    'Spanish':'İspanyolca',
    'Repair':'Onarım',
    'Home':'Ev',
    'Office':'Ofis',
    'Country house':'Köy evi',
    'Building':'İnşaat',
    'Designer':'Tasarımcı',
    'Planner':'Planlayıcı',
    'Car repair':'Araba tamiri',
    'Maintenance':'Bakım',
    'Tire service':'Lastik servisi',
    'Motor Mechanic':'Araba tamircisi',
    'Spare parts':'Yedek parça',
    'Restaurants':'Restoranlar',
    'Fast Food':'Hızlı Yemek',
    'Bistro':'Bistro',
    'Family':'Aile',
    'Beauty': 'Güzellik',
    'Barbarshop':'Barbershop',
    'Stylist':'Stilist',
    'Mail art':'Manikür',
    'Photograph':'Fotoğrafçı',
    'Wedding':'Düğün',
    'Spasial days':'Partiler',
    'Birthday party':'Doğum günleri',
    'Digital':'Dijital',
    'Developer':'Geliştirici',
    'Mobile APP':'Mobile APP',
    'Digital printing':'Mobil Uygulama',
    'Warehouse':'Depo',
    'Construction':'İnşaat Malz.',
    'Equipment':'Ekipman',
    'Parking lot':'Otopark',
    'Refrigerated':'Buzdolabı',
    'Worker':'İşçi',
    'Handyman':'Tamirci',
    'Plasterer':'Sıvacı',
    'Builder':'İnşaatçı',
    'Loader':'Yükleyici',
    'Heavy machine':'Ağır makine',
    'Trackers':'Traktörler',
    'Excavators':'Ekskavatörler',
    'Trailers':'Fragmanlar',
    "Customer":"Müşteri",
    "Executor":"Hizmet verici",
    "Leave an order with a task description.": "Görev açıklaması içeren bir sipariş bırakın..",
    "Executers will write themselves. We will  Show to suitable executor. They will write if they are willing to help.":"Hizmet vericiler kendileri yazacak. Uygun Hizmet vericiye göstereceğiz. Eğer yardım etmek isterlerse yazacaklar.",
    "Choose the right one, agree with him on all the details.":"Doğru olanı seçin ve onlarla tüm detaylar üzerinde anlaşın",
    "Choose suitable task for you.":"Sizin için uygun görevi seçin.",
    "Write if you are willing to help and write to customer.": "Yardım etmeye istekliyseniz müşteriye yazın.",
    "Agree on all the details with a customer.":"Bir müşteri ile tüm detaylar üzerinde anlaşın.",
    "Signing...":"Oturum Açılıyor ...",
    "Sign In Success": "Oturum Açma Başarısı",
    "Welcome back":"Tekrar hoş geldiniz",
    "Password entry rules:":"Parola giriş kuralları:",
    "Minimum of 8 characters":"Minimum 8 karakter",
    "1 capital letter":"1 büyük harf",
    "1 lowercase letter":"1 küçük harf.",
    "Minimum 1 digit":"Minimum 1 rakam",
    "Forgot Password?": "Şifremi Unuttum?",
    "Remember me": "Beni Hatırla.",
    "Please fill name field.": "Lütfen isim alanını doldurun.",
    "Please fill phone number field.":"Lütfen telefon numarası alanını doldurunuz.",
    "Please fill e-mail field.":"Lütfen e-posta alanını doldurunuz.",
    "Please fill message field.":"Lütfen mesaj alanını doldurunuz.",

    // new words 30.09.2023
    "Home cleaning": "Ev temizliği",
    "Worker Plaster": "İşçi Sıvacı",
    "Load carrier": "Yük taşıyıcı",
    "English teacher": "İngilizce öğretmeni",
    "Nurse": "Hemşire",
    "Crane": "Vinç",
    "Office cleaning": "Ofis temizliği",
    "Accounting consultant": "Muhasebe danışmanı",
    "Vegetarian Restaurant": "Vejetaryen Restoranı",
    "Lawyer Consultant": "Avukat Danışman",

    // advert texts
    "Adverts": "Reklamlar",

    "<0>Download our</0><br/>Application.": "<0>UYGULAMAMIZI</0><br/>indirin.",
}