import { useNavigate } from 'react-router-dom';
import { services } from '../../data/services';
import MoreButton from '../MoreBtn';
import SectionTitle from '../SectionTitle';
import styles from './styles.module.scss'
import { useTranslation, Trans } from "react-i18next";

function Services() {

    const navigate = useNavigate()
    const { t, i18n } = useTranslation();

    return ( 
        <section className={styles.section}>
            <div className="container">
                <SectionTitle text={t('An expert for every need')}/>
                <div className={styles.items}>
                    {
                        services.map(item => {
                            return (
                                <div key={item.img} className={styles.serviceItem}>
                                    <div className={styles.img}>
                                        <img src={item.img} alt={t(item.text)} />
                                    </div>
                                    <p className={styles.itemText}>{t(item.text)}</p>
                                </div>
                            )
                        })
                    }
                </div>
                <span onClick={() => navigate('/services')}><MoreButton/></span>
            </div>
        </section>
     );
}

export default Services;