import HowWorkHeader from '../Components/HowWorkHeader'
import HowWork from '../Components/HowWork'
import Professional from '../Components/Professional'
import HowWorksPay from '../Components/HowWorksPay';
import HowWorkSwitch from '../Components/HowWorkSwitch';
import { useEffect } from 'react';

function HowWorks({onChangeLogin, onChangeRegistration}) {

    useEffect(() => {
        window.scrollTo({top: 0, behavior: 'smooth'});
    }, [])

    return (
        <>
            <HowWorkHeader onChangeLogin={onChangeLogin} onChangeRegistration={onChangeRegistration}/>
            <HowWorkSwitch value={true} />
            <HowWork value={'customer'} />
            {/* <HowWorksPay /> */}
            <Professional />
        </>
    );
}

export default HowWorks;