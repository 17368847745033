import img from '../../assets/img/Group 65.png'
import styles from './styles.module.scss'
import { useTranslation, Trans } from "react-i18next";
import { useSelector, useDispatch } from 'react-redux'

import {currentState} from '../../redux/slices/auth'
import {useNavigate} from "react-router-dom";

function MainBanner({toggleDownloadModalState, onChangeRegistration}) {
    const { t, i18n } = useTranslation();
    const Auth = useSelector(currentState)
    const navigate = useNavigate()

    return ( 
        <section className={styles.section}>
            <div className="container">
                <div className={styles.content}>
                    <h3 className={styles.title}>{t("Free and without obligation.")}</h3>
                    <p className={styles.text}>{t("With BURABAK you will find the best experts in your field, whatever your needs.")}</p>
                    {
                        !(Auth.user === null || Auth.user.payload === null || Auth.user.payload === undefined || Auth.user.payload === '') && (
                            <button className={styles.button} onClick={() => {toggleDownloadModalState(true); navigate('/download');}}>{t("Download")}</button>
                        )
                    }
                    {
                        (Auth.user === null || Auth.user.payload === null || Auth.user.payload === undefined || Auth.user.payload === '') && (
                            <button className={styles.button} onClick={() => onChangeRegistration(true)}>{t("Register")}</button>        
                        )
                    }
                </div>
                <img src={img} alt="phone" className={styles.mainImage}/>
            </div>
        </section>
     );
}

export default MainBanner;