import img from '../../assets/img/Group 473784.png'
import social1 from '../../assets/img/play_store.png'
import social2 from '../../assets/img/app_store.png'
import social3 from '../../assets/img/app_gallery.png'
import styles from './styles.module.scss'
import QRCode from "react-qr-code";
import { useTranslation, Trans } from "react-i18next";

import constants from '../../Hooks/constants'

function MobileBanner() {
    const { t, i18n } = useTranslation();

    return ( 
        <section className={styles.section}>
            <div className="container" style={{display: 'flex'}}>
                <img src={img} alt="phone" className={styles.mainImage}/>
                <div style={{zIndex: 1}}>
                    <h3 className={styles.title}>{t("More suitable in app")}</h3>
                    <div className={styles.row}>
                        <a target="_blank" href={constants.google_store()}>
                            <img className={styles.icon} src={social1} alt="" />
                        </a>
                        <a target="_blank" href={constants.apple_store()}>
                            <img className={styles.icon} src={social2} alt="" />
                        </a>
                        <a target="_blank" href={constants.gallery_store()}>
                            <img className={styles.icon} src={social3} alt="" />
                        </a>
                    </div>
                    {/* <button className={styles.button}>{t("Download")}</button> */}
                </div>
                <div className={styles.qrContainer}>
                    <QRCode value="https://burabak.com/download" size={128} color={'black'} />
                </div>
            </div>
        </section>
     );
}

export default MobileBanner;