import styles from './styles.module.scss'
import img from '../../assets/img/625c63411881ca12a79cca4f20a89d54 1.png'
import { useTranslation, Trans } from "react-i18next";
import { useSelector, useDispatch } from 'react-redux'

import {currentState} from '../../redux/slices/auth'
import {useNavigate} from "react-router-dom";

function Join({toggleDownloadModalState, onChangeLogin, onChangeRegistration}) {
    const { t, i18n } = useTranslation();
    const Auth = useSelector(currentState)
    const navigate = useNavigate()

    return ( 
        <section className={styles.section}>
            <div className="container">
                
                <div className={styles.content}>
                    <h3 className={styles.title}>{t("Join BURBAK today")}</h3>
                    <p className={styles.text}>{t("Join BURABAK today and embark on a transformative journey. Experience the convenience, affordability, and sense of connection that service sharing brings. Together we can shape a brighter future where resources are shared, communities thrive and sustainable living is at hand.")}</p>
                    {
                        !(Auth.user === null || Auth.user.payload === null || Auth.user.payload === undefined || Auth.user.payload === '') && (
                            <button className={styles.button} onClick={() => {toggleDownloadModalState(true); navigate('/download');}}>{t("Download")}</button>
                        )
                    }
                    {
                        (Auth.user === null || Auth.user.payload === null || Auth.user.payload === undefined || Auth.user.payload === '') && (
                            <button className={styles.button} onClick={() => onChangeRegistration(true)}>{t("Join")}</button>
                        )
                    }
                </div>
                <img src={img} alt="work" className={styles.mainImage}/>
            </div>
        </section>
     );
}

export default Join;