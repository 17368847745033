import bg1 from '../assets/img/advert-bg1.jpg'
import bg2 from '../assets/img/advert-bg2.jpg'
import bg3 from '../assets/img/advert-bg3.jpg'

import icon1 from '../assets/img/advert-icon1.svg'
import icon2 from '../assets/img/advert-icon2.svg'
import icon3 from '../assets/img/advert-icon3.svg'

export const adverts = [
    {
        id: 1,
        title: 'YOGA lessons',
        text: '30% discount for the first months',
        date: '06/04/2023',
        icon: icon1,
        iconText: 'Health',
        bg: bg1,
    },
    {
        id: 2,
        title: 'Turkiye - Rusya TIR',
        text: 'Return 2 days after from Moscow. Return 2 days after Moskva',
        date: '05/04/2023',
        icon: icon2,
        iconText: 'Transport',
        bg: bg2,
    },
    {
        id: 3,
        title: 'Burgerland',
        text: '15% discount is waiting for you',
        date: '05/04/2023',
        icon: icon3,
        iconText: 'Ceremony',
        bg: bg3,
    },
    {
        id: 4,
        title: 'Burgerland',
        text: '15% discount is waiting for you',
        date: '05/04/2023',
        icon: icon3,
        iconText: 'Ceremony',
        bg: bg3,
    }
]