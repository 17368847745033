import AboutBanner from '../Components/AboutBanner';
import AboutBlock from '../Components/AboutBlock';
import AboutHeader from '../Components/AboutHeader';
import AboutInNumber from '../Components/AboutInNumbers';
import AboutPluses from '../Components/AboutPluses';
import Best from '../Components/Best';
import Join from '../Components/Join';
import { useEffect } from 'react';

function AboutUs({toggleDownloadModalState, onChangeLogin, onChangeRegistration}) {

    useEffect(() => {
        window.scrollTo({top: 0, behavior: 'smooth'});
    }, [])

    return ( 
        <>
            <AboutHeader  onChangeLogin={onChangeLogin} onChangeRegistration={onChangeRegistration}/>
            <AboutBlock/>
            <AboutInNumber/>
            <AboutPluses/>
            <AboutBanner/>
            <Best/>
            <Join toggleDownloadModalState={toggleDownloadModalState} onChangeLogin={onChangeLogin} onChangeRegistration={onChangeRegistration}/>
        </>
     );
}

export default AboutUs;