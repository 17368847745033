import bigBg1 from '../assets/img/Group 60.jpg'
import bigBg2 from '../assets/img/Rectangle 5.jpg'
import bigBg3 from '../assets/img/Group 6665.jpg'
import bigBg4 from '../assets/img/Group 61.jpg'

import bg1 from '../assets/img/Group 6666.jpg'
import bg2 from '../assets/img/Group 6667.jpg'
import bg3 from '../assets/img/Group 6668.jpg'
import bg4 from '../assets/img/Rectangle 7.jpg'
import bg5 from '../assets/img/Group 6669.jpg'
import bg6 from '../assets/img/Group 6670.jpg'

export const bigCategories = [
    {
        id: 1,
        bg: bigBg1,
        text: 'Home cleaning'
    },
    {
        id: 2,
        bg: bigBg2,
        text: 'Worker Plaster'
    },
    {
        id: 3,
        bg: bigBg3,
        text: 'Load carrier'
    },
    {
        id: 4,
        bg: bigBg4,
        text: 'English teacher'
    },
]


export const categories = [
    {
        id: 1,
        bg: bg1,
        text: 'Nurse'
    },
    {
        id: 2,
        bg: bg2,
        text: 'Crane'
    },
    {
        id: 3,
        bg: bg3,
        text: 'Office cleaning'
    },
    {
        id: 4,
        bg: bg4,
        text: 'Accounting consultant'
    },
    {
        id: 5,
        bg: bg5,
        text: 'Vegetarian Restaurant'
    },
    {
        id: 6,
        bg: bg6,
        text: 'Lawyer Consultant'
    },
]