export default {
    "More than 800 services are<br/><2>waiting for you.</2>": "More than 800 services are<br/><2>waiting for you.</2>",
    "Nearby": "Nearby",
    "Find": "Find",
    "Your safety and trust are our top priorities.": "Your safety and trust are our top priorities.",
    "We help you make informed decisions, provide a rating and review system based on the experiences of other users.": "We help you make informed decisions, provide a rating and review system based on the experiences of other users.",
    "With BURABAK, you can experience the comfort of knowing that your service needs are in competent hands.": "With BURABAK, you can experience the comfort of knowing that your service needs are in competent hands.",
    "At BURBAK, we are passionate about ": "At BURABAK, we are passionate about ",
    "transforming the way individuals and businesses access services. Our mission is to create a more sustainable and connected world by promoting resource sharing and community building.": "transforming the way individuals and businesses access services. Our mission is to create a more sustainable and connected world by promoting resource sharing and community building.",
    "We believe everyone should have easy access to the resources and expertise they need, regardless of traditional barriers.": "We believe everyone should have easy access to the resources and expertise they need, regardless of traditional barriers.",
    "With BURABAK, you can access a wide variety of services to suit your needs. If you need transportation, repair and maintenance, personal care or any other service, our platform will help you.": "With BURABAK, you can access a wide variety of services to suit your needs. If you need transportation, repair and maintenance, personal care or any other service, our platform will help you.",
    "<0>Revolutionizing Service-Sharing</0><br/>for a Connected World.": "<0>Revolutionizing Service-Sharing</0><br/>for a Connected World.",
    "about us by numbers": "about us by numbers",
    "Professionals": "Professionals",
    "Services": "Services",
    "Customers": "Customers",
    "Simplicity": "Simplicity",
    "Our user-friendly platform allows you to connect with service providers in your area with just a few clicks.": "Our user-friendly platform allows you to connect with service providers in your area or out with just a few clicks.",
    "Costs": "Costs",
    "We understand that cost is important, which is why our service providers offer competitive prices.": "We understand that the cost of services is important, which is why our service providers offer competitive prices.",
    "Location": "Location",
    "With our location-based search, it's easy to find someone nearby for help.": "With our location-based search, it's easy to find someone nearby for help.",
    "<0>User  </0>Agreement.": "<0>User  </0>Agreement.",
    "Why we are the Best": "Why we are the Best",
    "We are constantly improving our platform in line with your feedback and suggestions. Your contributions are invaluable in improving our services and making your experience even better.": "We are constantly improving our platform in line with your feedback and suggestions. Your contributions are invaluable in improving our services and making your experience even better.",
    "Our commitment to innovation drives us to explore new possibilities and bring you the best possible service sharing experience.": "Our commitment to innovation drives us to explore new possibilities and bring you the best possible service sharing experience.",
    "Get in touch": "Get in touch",
    "Contact form": "Contact form",
    "Full name": "Full name",
    "Enter your name": "Enter your name",
    "Enter your full name": "Enter your full name",
    "Phone": "Phone",
    "E-mail": "E-mail",
    "Enter your E-mail": "Enter your E-mail",
    "Message": "Message",
    "Enter your message or question": "Enter your message or question",
    "Send message": "Send message",
    "INFO": "INFO",
    "About us": "About us",
    "Contact us": "Contact us",
    "How it work?": "How it works?",
    "ADDITIONAL": "ADDITIONAL",
    "Support": "Support",
    "Privacy Policy": "Privacy Policy",
    "FAQ": "FAQ",
    "User Agreement": "User Agreement",
    "TO REACH US": "TO REACH US",
    "All Rights Reserved.": "All Rights Reserved.",
    "Sign In": "Sign In",
    "Sign Up": "Sign Up",
    "Sign Out": "Sign Out",
    "How does this work": "How it works",
    "About": "About Us",
    "Login": "Sign In",
    "Join us": "Sign Up",
    "What do you want?": "What do you need?",
    "Where?": "Where?",
    "Write your needs in a few words": "Write your needs in a few words",
    "See experts on demand": "See experts on demand",
    "Compare prices and choose": "Compare prices and choose",
    "Join BURBAK today": "Join BURABAK today",
    "Join BURABAK today and embark on a transformative journey. Experience the convenience, affordability, and sense of connection that service sharing brings. Together we can shape a brighter future where resources are shared, communities thrive and sustainable living is at hand.": "Join BURABAK today and embark on a transformative journey. Experience the convenience, affordability, and sense of connection that service sharing brings. Together we can shape a brighter future where resources are shared, communities thrive and sustainable living is at hand.",
    "Join": "Join",
    "Free and without obligation.": "Free and without obligation.",
    "With BURABAK you will find the best experts in your field, whatever your needs.": "With BURABAK you will find the best experts in your field, whatever your needs.",
    "Register": "Register",
    "More suitable in app": "More suitable in app",
    "Download": "Download",
    "Enter your phone number": "Enter your phone number",
    "Password": "Password",
    "Enter your password": "Enter your password",
    "I forgot password": "I forgot password",
    "Hide": "Hide",
    "Show": "Show",
    "More": "More",
    "Popular Categories": "Popular Categories",
    "Are you professional?": "Are you professional?",
    "BURABAK Your partner for finding new customers online": "BURABAK Your partner for finding new customers online",
    "Details": "Details",
    "An expert for every need": "An expert for every need",
    "By clicking Continue, I accept the <1>Membership Terms</1>.": "By clicking Continue, I accept the <1>Membership Terms</1>.",
    "Send code": "Send code",
    "An SMS with a confirmation code has been sent to your phone number. Enter it in the field below and click 'Continue'": "An SMS with a confirmation code has been sent to your phone number. Enter it in the field below and click 'Continue'",
    "Send SMS": "Send SMS",
    "Surname": "Surname",
    "Man": "Man",
    "Woman" : "Woman",
    "Next": "Next",
    "<0>Privacy  </0>Policy.": "<0>Privacy  </0>Policy.",
    "<0>HOW IT  </0><br/>Work?": "<0>HOW IT  </0><br/>Work?",
    "welcome_professional": "Are you a professional? Do you want a part-time job? Are you looking for a job? Then we're the place to be. Register and fill out the form. At your discretion, you will be able to see orders or requests for work near or in another city. You can respond and negotiate and start working. Only pay a monthly or annual symbolic subscription fee and work when you want or sit at home and look for an order.",
    "welcome_customer": "We help you make informed decisions and provide a rating and feedback system based on the experience of other users. With BURABAK you can be sure that your service is in competent hands. Just leave an order with the terms and conditions and the nearest professional will respond. We do not require any amount of money for this service and you can agree on the service of a professional yourself.",
    "click to pay": "click to pay",
    "include all system no extra<br/> payment needed": "all in one system no extra<br/> payment is needed",
    "Download our mobile application": "Download our mobile application",
    "Scan the QR code and download our mobile app": "Scan the QR code and download our mobile app",
    "Sneak peek1": "Sneak peek into our project's soul.",
    "Sneak peek2": "Features are poised to shine, soon gracing the website.",
    "Sneak peek5": "Dive in, collaborate, follow, and share ideas with us.",
    "Sneak peek3": "us up on our site!",
    "Sneak peek4": "Hit",
    "OK": "OK",
    "Create an account here": "Create an account here",
    "Invalid Code!": "Invalid Code!",
    "Please Input Phone Number": "Please Input Phone Number",
    "Please Input Password": "Please Input Password.",
    "Resend in": "Resend in",
    "Password Length Must Be Greater Than 8.": "Password Length Must Be Greater Than 8.",
    "Please Check Your Network Status": "Please Check Your Network Status",
    "User Already Exists": "User Already Exists",
    "Your phone number": "Your phone number",
    "One more time": "One more time",
    "New Password": "New Password",
    "Go": "Go",
    "User Not Exists": "User Not Exists",
    "Sign Up Success": "Sign Up Success",
    "Please confirm your password.": "Please confirm your password.",
    "Success": "Success",
    "By clicking Continue, I accept the <1>User Agreement</1> and <3>Privacy Policy</3>.": "By clicking Continue, I accept the <1>User Agreement</1> and <3>Privacy Policy</3>.",
    "Please Input Full Name": "Please Input Full Name",
    "Please Input Sur Name": "Please Input Sur Name",
    "Please Input Password One More Time": "Please Input Password One More Time",
    "House Cleaning": "House Cleaning",
    "Office Cleaning": "Office Cleaning",
    "After renovation": "After renovation",
    "Deep cleaning": "Deep cleaning",
    "Cleaning": "Cleaning",
    "Ceremony": "Ceremony",
    "Wedding halls": "Wedding halls",
    "Musicans": "Musicians",
    "Mourning halls": "Mourning halls",
    "Wedding cars": "Wedding cars",
    "Legal": "Legal",
    "Legal Advisor":"Legal Advisor",
    "Corporate lawyer":"Corporate lawyer",
    "Real Estate":"Real Estate",
    "Accounting":"Accounting",
    "Financial Advisor":"Financial Advisor",
    "Accountant":"Accountant",
    "Stock advisor":"Stock advisor",
    "Health":"Health",
    "Nurses":"Nurses",
    "Pharmacy":"Pharmacy",
    "Emergency":"Emergency",
    "Clinics":"Clinics",
    "Sport":"Sport",
    "GYM":"GYM",
    "Body-building":"Body-building",
    "Boxing":"Boxing",
    "Swiming pool":"Swiming",
    "Transport":"Transport",
    "Home to Home":"Home to Home",
    'Office to Office':'Office to Office',
    'In Country':'In Country',
    'Out of Country':'Out of Country',
    'Private lesson':'Private lesson',
    'Maths':'Maths',
    'English':'English',
    'Germany':'Germany',
    'Spanish':'Spanish',
    'Repair':'Repair',
    'Home':'Home',
    'Office':'Office',
    'Country house':'Country house',
    'Building':'Building',
    'Designer':'Designer',
    'Planner':'Planner',
    'Car repair':'Car repair',
    'Maintenance':'Maintenance',
    'Tire service':'Tire service',
    'Motor Mechanic':'Motor Mechanic',
    'Spare parts':'Spare parts',
    'Restaurants':'Restaurants',
    'Fast Food':'Fast Food',
    'Bistro':'Bistro',
    'Family':'Family',
    'Beauty': 'Beauty',
    'Barbarshop':'Barbarshop',
    'Stylist':'Stylist',
    'Mail art':'Nail art',
    'Photograph':'Photograph',
    'Wedding':'Wedding',
    'Spasial days':'Party',
    'Birthday party':'Birthday party',
    'Digital':'Digital',
    'Developer':'Developer',
    'Mobile APP':'Mobile APP',
    'Digital printing':'Digital printing',
    'Warehouse':'Warehouse',
    'Construction':'Construction',
    'Equipment':'Equipment',
    'Parking lot':'Parking lot',
    'Refrigerated':'Refrigerated',
    'Worker':'Worker',
    'Handyman':'Handyman',
    'Plasterer':'Plasterer',
    'Builder':'Builder',
    'Loader':'Loader',
    'Heavy machine':'Heavy machine',
    'Trackers':'Tractors',
    'Excavators':'Excavators',
    'Trailers':'Trailers',
    "Customer":"Customer",
    "Executor":"Executor",
    "Leave an order with a task description.": "Leave an order with a task description.",
    "Executers will write themselves. We will  Show to suitable executor. They will write if they are willing to help.":"Executers will write themselves. We will  Show to suitable executor. They will write if they are willing to help.",
    "Choose the right one, agree with him on all the details.":"Choose the right one, agree with him on all the details.",
    "Choose suitable task for you.":"Choose suitable task for you.",
    "Write if you are willing to help and write to customer.": "Write to the customer if you are ready to get the task and help.",
    "Agree on all the details with a customer.":"Agree on all the details with a customer.",
    "Signing...":"Signing...",
    "Sign In Success": "Sign In Success",
    "Welcome back":"Welcome back",
    "Password entry rules:":"Password entry rules:",
    "Minimum of 8 characters":"Minimum of 8 characters",
    "1 capital letter":"1 capital letter",
    "1 lowercase letter":"1 lowercase letter",
    "Minimum 1 digit":"Minimum 1 digit",
    "Forgot Password?": "Forgot Password?",
    "Remember me": "Remember me",
    "Please fill name field.": "Please fill name field.",
    "Please fill phone number field.":"Please fill phone number field.",
    "Please fill e-mail field.":"Please fill e-mail field.",
    "Please fill message field.":"Please fill message field.",

    // new words 30.09.2023
    "Home cleaning": "Home cleaning",
    "Worker Plaster": "Worker Plaster",
    "Load carrier": "Load carrier",
    "English teacher": "English teacher",
    "Nurse": "Nurse",
    "Crane": "Crane",
    "Office cleaning": "Office cleaning",
    "Accounting consultant": "Accounting consultant",
    "Vegetarian Restaurant": "Vegetarian Restaurant",
    "Lawyer Consultant": "Lawyer Consultant",

    // advert texts
    "Adverts": "Adverts",

    "<0>Download our</0><br/>Application.": "<0>Download our</0><br/>APPLICATION.",
}