import { servicesAll } from '../../data/fullServices';
import SectionTitle from '../SectionTitle';
import styles from './styles.module.scss'
import { useTranslation, Trans } from "react-i18next";
import { useSelector } from 'react-redux';
import {currentState} from '../../redux/slices/auth';
import {useNavigate} from "react-router-dom";

function ServicesFull({onChangeRegistration, toggleDownloadModalState}) {
    const { t, i18n } = useTranslation();

    const navigate = useNavigate()
    const Auth = useSelector(currentState)

    const onMore = () => {
        if(Auth.user === null || Auth.user.payload === null || Auth.user.payload === undefined || Auth.user.payload === '') {
            onChangeRegistration(true);
        } else {
            navigate('/download')
            toggleDownloadModalState(true);
        }
    }

    return ( 
        <section className={styles.section}>
            <div className="container">
                <SectionTitle text={t('An expert for every need')}/>
                <div className={styles.services}>
                    {
                        servicesAll.map(item => {
                            return (
                                <div className={styles.item} key={item.id}>
                                    <div className={styles.iconWrap}>
                                        <img src={item.icon} alt="" />
                                    </div>
                                    <p className={styles.itemTitle}>{t(item.title)}</p>
                                    {
                                        item.shortLinks.map(link => {
                                            return (
                                                <p key={link.id} className={styles.link}>{t(link.text)} <span>({link.objects})</span></p>
                                            )
                                        })
                                    }
                                    <p onClick={onMore} className={styles.more}>{t("More")}</p>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </section>
     );
}

export default ServicesFull;