import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/navigation';
import 'swiper/css';
import styles from './styles.module.scss'
import { adverts } from '../../../data/adverts';
import { Navigation } from 'swiper/modules';

function AdvertSlider() {
    return (
        <Swiper
            spaceBetween={50}
            slidesPerView={3}
            onSlideChange={() => console.log('slide change')}
            onSwiper={(swiper) => console.log(swiper)}
            className={styles.slider}
            navigation={true} 
            modules={[Navigation]}
            breakpoints={{
                // when window width is >= 640px
                250: {
                  slidesPerView: 1,
                },
                // when window width is >= 768px
                900: {
                  slidesPerView: 3,
                },
              }}
        >
            {
                adverts.map(item => {
                    return (
                        <SwiperSlide key={item.id} className={styles.slide} style={{background: `url(${item.bg})`}}>
                            <p className={styles.title}>{item.title}</p>
                            <p className={styles.text}>{item.text}</p>
                            <p className={styles.date}>{item.date}</p>
                            <div className={styles.iconBlock}>
                                <img src={item.icon} alt={item.iconText} />
                                <p className={styles.iconText}>{item.iconText}</p>
                            </div>
                        </SwiperSlide>
                    )
                })
            }
        </Swiper>
    );
}

export default AdvertSlider;