import HeaderTop from '../Header/HeaderTop';
import styles from './styles.module.scss'
import { useTranslation, Trans } from "react-i18next";
import constants from "../../Hooks/constants";
import img from "../../assets/img/Group 473784.png";
import social1 from "../../assets/img/play_store.png";
import social2 from "../../assets/img/app_store.png";
import social3 from "../../assets/img/app_gallery.png";
import {BrowserView, MobileView} from 'react-device-detect';

function DownloadHeader({onChangeLogin, onChangeRegistration}) {
    const { t, i18n } = useTranslation();

    return ( 
        <div className={styles.content}>
            <HeaderTop viewType={'map'}  onChangeLogin={onChangeLogin} onChangeRegistration={onChangeRegistration}/>
            <div className="container">
                <h1 className={styles.title}><Trans><span>Download our</span><br/>Application.</Trans></h1>

                <MobileView>
                    <div className={styles.column_mobile}>
                        <div className={styles.row_mobile}>
                            <a target="_blank" href={constants.google_store()}>
                                <img className={styles.icon} src={social1} alt="" />
                            </a>
                        </div>
                        <div className={styles.row_mobile}>
                            <a target="_blank" href={constants.apple_store()}>
                                <img className={styles.icon} src={social2} alt="" />
                            </a>
                        </div>
                        <div className={styles.row_mobile}>
                            <a target="_blank" href={constants.gallery_store()}>
                                <img className={styles.icon} src={social3} alt="" />
                            </a>
                        </div>
                    </div>
                </MobileView>

                <BrowserView>
                    <div className={styles.column}>
                        <div className={styles.row}>
                            <a target="_blank" href={constants.google_store()}>
                                <img className={styles.icon} src={social1} alt="" />
                            </a>
                        </div>
                        <div className={styles.row}>
                            <a target="_blank" href={constants.apple_store()}>
                                <img className={styles.icon} src={social2} alt="" />
                            </a>
                        </div>
                        <div className={styles.row}>
                            <a target="_blank" href={constants.gallery_store()}>
                                <img className={styles.icon} src={social3} alt="" />
                            </a>
                        </div>
                    </div>
                </BrowserView>

            </div>
        </div>
     );
}

export default DownloadHeader;