export default {
    "More than 800 services are<br/><2>waiting for you.</2>": "Mehr als 800<br/><2>Dienste warten auf Sie.</2>",
    "Nearby": "Nähe",
    "Find": "Finde",
    "Your safety and trust are our top priorities.": "Ihre Sicherheit und Ihr Vertrauen haben für uns oberste Priorität.",
    "We help you make informed decisions, provide a rating and review system based on the experiences of other users.": "Wir helfen Ihnen, fundierte Entscheidungen zu treffen, indem wir ein Bewertungs- und Rezensionssystem anbieten, das auf den Erfahrungen anderer Nutzer basiert.",
    "With BURABAK, you can experience the comfort of knowing that your service needs are in competent hands.": "Bei BURABAK können Sie sich darauf verlassen, dass Ihr Service in kompetenten Händen ist.",
    "At BURBAK, we are passionate about ": "BURABAK hat es sich zur",
    "transforming the way individuals and businesses access services. Our mission is to create a more sustainable and connected world by promoting resource sharing and community building.": "Aufgabe gemacht, die Art und Weise, wie Privatpersonen und Unternehmen auf Dienstleistungen zugreifen, zu verändern. Unser Ziel ist es, eine nachhaltigere und vernetztere Welt zu schaffen, indem wir den Austausch von Ressourcen und den Aufbau von Gemeinschaften erleichtern.",
    "We believe everyone should have easy access to the resources and expertise they need, regardless of traditional barriers.": "Wir sind der Meinung, dass alle Menschen leichten Zugang zu den Ressourcen und dem Wissen haben sollten, das sie benötigen, unabhängig von traditionellen Barrieren.",
    "With BURABAK, you can access a wide variety of services to suit your needs. If you need transportation, repair and maintenance, personal care or any other service, our platform will help you.": "Mit BURABAK können Sie auf eine breite Palette von Dienstleistungen zugreifen, die Ihren Bedürfnissen entsprechen. Ob Sie Transport, Reparaturen und Wartung, persönliche Betreuung oder andere Dienstleistungen benötigen, unsere Plattform kann Ihnen helfen.",
    "<0>Revolutionizing Service-Sharing</0><br/>for a Connected World.": "<0>Eine Revolution im Service</0><br/>in einer vernetzten Welt.",
    "about us by numbers": "Wir über uns in Zahlen",
    "Professionals": "Fachleute",
    "Services": "Dienste",
    "Customers": "Kunden",
    "Simplicity": "Einfachheit",
    "Our user-friendly platform allows you to connect with service providers in your area with just a few clicks.": "Unsere benutzerfreundliche Plattform ermöglicht es Ihnen, mit nur wenigen Klicks mit Dienstleistern in Ihrer Region oder darüber hinaus in Verbindung zu treten.",
    "Costs": "Kosten",
    "We understand that cost is important, which is why our service providers offer competitive prices.": "Wir wissen, dass die Kosten eine wichtige Rolle spielen, weshalb unsere Dienstleister wettbewerbsfähige Preise anbieten.",
    "Location": "Standort",
    "With our location-based search, it's easy to find someone nearby for help.": "Mit einer Standortsuche können Sie leicht jemanden in Ihrer Nähe finden, der Ihnen hilft.",
    "<0>User  </0>Agreement.": "<0>Benutzervereinbarung</0>",
    "Why we are the Best": "Warum wir die Besten sind",
    "We are constantly improving our platform in line with your feedback and suggestions. Your contributions are invaluable in improving our services and making your experience even better.": "Wir arbeiten ständig an der Verbesserung unserer Plattform auf der Grundlage Ihres Feedbacks und Ihrer Vorschläge. Ihr Input ist von unschätzbarem Wert für die Verbesserung unserer Dienstleistungen und der Servicequalität.",
    "Our commitment to innovation drives us to explore new possibilities and bring you the best possible service sharing experience.": "Unser Engagement für Innovation treibt uns an, neue Möglichkeiten zu erforschen und Ihnen die bestmögliche gemeinsame Serviceerfahrung zu bieten.",
    "Get in touch": "Kontaktieren Sie uns",
    "Contact form": "Kontakt Formular",
    "Full name": "Voller Name",
    "Enter your name": "Gib deinen Namen ein",
    "Enter your full name": "Geben Sie Ihren vollen Namen ein",
    "Phone": "Telefon",
    "E-mail": "E-mail",
    "Enter your E-mail": "Geben Sie Ihre E-Mail ein",
    "Message": "Botschaft",
    "Enter your message or question": "Ihre Nachricht oder Frage eingeben",
    "Send message": "Nachricht senden",
    "INFO": "INFO",
    "About us": "Über uns",
    "Contact us": "Kontaktieren Sie uns",
    "How it work?": "Wie funktioniert das?",
    "ADDITIONAL": "ADDENDUM",
    "Support": "Unterstützen",
    "Privacy Policy": "Datenschutzrichtlinie",
    "FAQ": "FAQ",
    "User Agreement": "Nutzungsvertrag",
    "TO REACH US": "KONTAKT US",
    "All Rights Reserved.": "Alle Rechte vorbehalten.",
    "Sign In": "Eintrag",
    "Sign Up": "Anmeldung",
    "Sign Out": "Abmelden",
    "How does this work": "Wie es funktioniert",
    "About": "Über uns",
    "Login": "Eintrag",
    "Join us": "Anmeldung",
    "What do you want?": "Wonach suchen Sie?",
    "Where?": "Wo?",
    "Write your needs in a few words": "Schreiben Sie Ihre Anforderungen in wenigen Worten",
    "See experts on demand": "Experten auf Anfrage anzeigen",
    "Compare prices and choose": "Preise vergleichen und wählen",
    "Join BURBAK today": "Werden Sie noch heute Mitglied von BURABAK",
    "Join BURABAK today and embark on a transformative journey. Experience the convenience, affordability, and sense of connection that service sharing brings. Together we can shape a brighter future where resources are shared, communities thrive and sustainable living is at hand.": "Werden Sie noch heute Mitglied bei BURABAK und machen Sie sich auf die Reise. Erleben Sie den Komfort, die Zugänglichkeit und das Gefühl der Verbundenheit, das sich aus der gemeinsamen Nutzung von Dienstleistungen ergibt. Gemeinsam können wir eine strahlende Zukunft gestalten, in der Ressourcen geteilt werden, Gemeinschaften gedeihen und das Leben nachhaltig ist.",
    "Join": "Beitreten",
    "Free and without obligation.": "Unentgeltlich und unverbindlich.",
    "With BURABAK you will find the best experts in your field, whatever your needs.": "Bei BURABAK finden Sie die besten Experten auf Ihrem Gebiet, egal was Sie brauchen.",
    "Register": "Anmeldung",
    "More suitable in app": "Mehr Funktionen in der App",
    "Download": "Download",
    "Enter your phone number": "Eingabe der Rufnummer",
    "Password": "Passwort",
    "Enter your password": "Ihr Passwort eingeben",
    "I forgot password": "Ich habe mein Passwort vergessen.",
    "Hide": "Verbergen",
    "Show": "Zeige",
    "More": "Mehr",
    "Popular Categories": "Beliebte Kategorien",
    "Are you professional?": "Sind Sie ein Profi?",
    "BURABAK Your partner for finding new customers online": "BURABAK ist Ihr Partner für die Suche nach neuen Kunden im Internet",
    "Details": "Details",
    "An expert for every need": "Spezialist für alle Anlässe",
    "By clicking Continue, I accept the <1>Membership Terms</1>.": "Wenn ich auf Weiter klicke, akzeptiere ich die <1>Nutzungsvereinbarung</1>.",
    "Send code": "Code senden",
    "An SMS with a confirmation code has been sent to your phone number. Enter it in the field below and click 'Continue'": "Sie haben eine SMS-Nachricht mit einem Bestätigungscode an Ihre Telefonnummer erhalten. Geben Sie ihn in das unten stehende Feld ein und drücken Sie auf die Schaltfläche `Weiter`.",
    "Send SMS": "SMS senden",
    "Surname": "Familienname",
    "Man": "Mann",
    "Woman" : "Frau",
    "Next": "Weiter",
    "<0>Privacy  </0>Policy.": "<0>Datenschutzrichtlinie.</0>",
    "<0>HOW IT  </0><br/>Work?": "<0>WIE FUNKTIONIERT </0><br/>DAS?",
    "welcome_professional": "Are you a professional? Do you want a part-time job? Are you looking for a job? Then we're the place to be. Register and fill out the form. At your discretion, you will be able to see orders or requests for work near or in another city. You can respond and negotiate and start working. Only pay a monthly or annual symbolic subscription fee and work when you want or sit at home and look for an order.",
    "welcome_customer": "We help you make informed decisions and provide a rating and feedback system based on the experience of other users. With BURABAK you can be sure that your service is in competent hands. Just leave an order with the terms and conditions and the nearest professional will respond. We do not require any amount of money for this service and you can agree on the service of a professional yourself.",
    "click to pay": "click to pay",
    "include all system no extra<br/> payment needed": "all in one system no extra<br/> payment is needed",
    "Download our mobile application": "Download our mobile application",
    "Scan the QR code and download our mobile app": "Scan the QR code and download our mobile app",
    "Sneak peek1": "Sneak peek into our project's soul.",
    "Sneak peek2": "Features are poised to shine, soon gracing the website.",
    "Sneak peek5": "Dive in, collaborate, follow, and share ideas with us.",
    "Sneak peek3": "us up on our site!",
    "Sneak peek4": "Hit",
    "OK": "OK",
    "Create an account here": "Create an account here",
    "Invalid Code!": "Invalid Code!",
    "Please Input Phone Number": "Please Input Phone Number",
    "Please Input Password": "Please Input Password.",
    "Resend in": "Resend in",
    "Password Length Must Be Greater Than 8.": "Password Length Must Be Greater Than 8.",
    "Please Check Your Network Status": "Please Check Your Network Status",
    "User Already Exists": "User Already Exists",
    "Your phone number": "Your phone number",
    "One more time": "One more time",
    "New Password": "New Password",
    "Go": "Go",
    "User Not Exists": "User Not Exists",
    "Sign Up Success": "Sign Up Success",
    "Please confirm your password.": "Please confirm your password.",
    "Success": "Success",
    "By clicking Continue, I accept the <1>User Agreement</1> and <3>Privacy Policy</3>.": "By clicking Continue, I accept the <1>User Agreement</1> and <3>Privacy Policy</3>.",
    "Please Input Full Name": "Please Input Full Name",
    "Please Input Sur Name": "Please Input Sur Name",
    "Please Input Password One More Time": "Please Input Password One More Time",
    "House Cleaning": "House Cleaning",
    "Office Cleaning": "Office Cleaning",
    "After renovation": "After renovation",
    "Deep cleaning": "Deep cleaning",
    "Cleaning": "Cleaning",
    "Ceremony": "Ceremony",
    "Wedding halls": "Wedding halls",
    "Musicans": "Musicians",
    "Mourning halls": "Mourning halls",
    "Wedding cars": "Wedding cars",
    "Legal": "Legal",
    "Legal Advisor":"Legal Advisor",
    "Corporate lawyer":"Corporate lawyer",
    "Real Estate":"Real Estate",
    "Accounting":"Accounting",
    "Financial Advisor":"Financial Advisor",
    "Accountant":"Accountant",
    "Stock advisor":"Stock advisor",
    "Health":"Health",
    "Nurses":"Nurses",
    "Pharmacy":"Pharmacy",
    "Emergency":"Emergency",
    "Clinics":"Clinics",
    "Sport":"Sport",
    "GYM":"GYM",
    "Body-building":"Body-building",
    "Boxing":"Boxing",
    "Swiming pool":"Swiming",
    "Transport":"Transport",
    "Home to Home":"Home to Home",
    'Office to Office':'Office to Office',
    'In Country':'In Country',
    'Out of Country':'Out of Country',
    'Private lesson':'Private lesson',
    'Maths':'Maths',
    'English':'English',
    'Germany':'Germany',
    'Spanish':'Spanish',
    'Repair':'Repair',
    'Home':'Home',
    'Office':'Office',
    'Country house':'Country house',
    'Building':'Building',
    'Designer':'Designer',
    'Planner':'Planner',
    'Car repair':'Car repair',
    'Maintenance':'Maintenance',
    'Tire service':'Tire service',
    'Motor Mechanic':'Motor Mechanic',
    'Spare parts':'Spare parts',
    'Restaurants':'Restaurants',
    'Fast Food':'Fast Food',
    'Bistro':'Bistro',
    'Family':'Family',
    'Beauty': 'Beauty',
    'Barbarshop':'Barbarshop',
    'Stylist':'Stylist',
    'Mail art':'Nail art',
    'Photograph':'Photograph',
    'Wedding':'Wedding',
    'Spasial days':'Party',
    'Birthday party':'Birthday party',
    'Digital':'Digital',
    'Developer':'Developer',
    'Mobile APP':'Mobile APP',
    'Digital printing':'Digital printing',
    'Warehouse':'Warehouse',
    'Construction':'Construction',
    'Equipment':'Equipment',
    'Parking lot':'Parking lot',
    'Refrigerated':'Refrigerated',
    'Worker':'Worker',
    'Handyman':'Handyman',
    'Plasterer':'Plasterer',
    'Builder':'Builder',
    'Loader':'Loader',
    'Heavy machine':'Heavy machine',
    'Trackers':'Tractors',
    'Excavators':'Excavators',
    'Trailers':'Trailers',
    "Customer":"Customer",
    "Executor":"Executor",
    "Leave an order with a task description.": "Leave an order with a task description.",
    "Executers will write themselves. We will  Show to suitable executor. They will write if they are willing to help.":"Executers will write themselves. We will  Show to suitable executor. They will write if they are willing to help.",
    "Choose the right one, agree with him on all the details.":"Choose the right one, agree with him on all the details.",
    "Choose suitable task for you.":"Choose suitable task for you.",
    "Write if you are willing to help and write to customer.": "Write to the customer if you are ready to get the task and help.",
    "Agree on all the details with a customer.":"Agree on all the details with a customer.",
    "Signing...":"Signing...",
    "Sign In Success": "Sign In Success",
    "Welcome back":"Welcome back",
    "Password entry rules:":"Password entry rules:",
    "Minimum of 8 characters":"Minimum of 8 characters",
    "1 capital letter":"1 capital letter",
    "1 lowercase letter":"1 lowercase letter",
    "Minimum 1 digit":"Minimum 1 digit",
    "Forgot Password?": "Forgot Password?",
    "Remember me": "Remember me",
    "Please fill name field.": "Please fill name field.",
    "Please fill phone number field.":"Please fill phone number field.",
    "Please fill e-mail field.":"Please fill e-mail field.",
    "Please fill message field.":"Please fill message field.",

    // new words 30.09.2023
    "Home cleaning": "Hausreinigung",
    "Worker Plaster": "Arbeiter Gips",
    "Load carrier": "Lastenträger",
    "English teacher": "Englischlehrer",
    "Nurse": "Krankenschwester",
    "Crane": "Kran",
    "Office cleaning": "Büroreinigung",
    "Accounting consultant": "Berater für Buchhaltung",
    "Vegetarian Restaurant": "Vegetarisches Restaurant",
    "Lawyer Consultant": "Jurist Berater",

    // advert texts
    "Adverts": "Anzeigen",

    "<0>Download our</0><br/>Application.": "<0>Laden Sie unsere</0><br/>ANWENDUNG herunter.",
}