import styles from './styles.module.scss'
import { useTranslation, Trans } from "react-i18next";

function MoreButton() {
    const { t, i18n } = useTranslation();

    return ( 
        <button className={styles.more}>{t("More")}</button>
     );
}

export default MoreButton;