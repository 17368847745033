import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import en from './lang/en'
import az from './lang/az'
import ru from './lang/ru'
import tr from './lang/tr'
import ar from './lang/ar'
import ge from './lang/ge'

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: {
      en: {
        translations: en
      },
      az: {
        translations: az
      },
      ru: {
        translations: ru
      },
      tr: {
        translations: tr
      },
      ar: {
        translations: ar
      },
      ge: {
        translations: ge
      }
    },
    fallbackLng: "en",
    debug: true,

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
