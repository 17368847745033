import { useTranslation, Trans } from "react-i18next";
import Icon from '../../assets/img/Subtract.png';
import Next from '../../assets/img/Group 473868.png';
import styles from './styles.module.scss'
import { useSelector } from 'react-redux';
import {currentState} from '../../redux/slices/auth';
import {useNavigate} from "react-router-dom";

const HowWorksPay = ({onChangeRegistration, toggleDownloadModalState}) => {
    const { t, i18n } = useTranslation();

    const navigate = useNavigate()
    const Auth = useSelector(currentState)

    const onPay = () => {
        if(Auth.user === null || Auth.user.payload === null || Auth.user.payload === undefined || Auth.user.payload === '') {
            onChangeRegistration(true);
        } else {
            navigate('/download')
            toggleDownloadModalState(true);
        }
    }

    return (
        <section className={styles.section} id="HowWorksPay">
            <div className='container'>
                <div className={styles.cards}>
                    <div className={styles.card + ' ' + styles.monthly}>
                        <div className={styles.borderContent}>
                            <img src={Icon} className={styles.icon} />

                            <p className={styles.price}>6 USD</p>
                            <p className={styles.description}><Trans>include all system no extra<br/> payment needed</Trans></p>
                            <div className={styles.bottom}>
                                <img src={Next} style={{visibility: 'hidden'}} />
                                <p onClick={onPay} className={styles.pay}>{t("click to pay")}</p>
                                <img onClick={onPay} src={Next} className={styles.next} />
                            </div>
                        </div>
                        <div className={styles.badge}>
                            <p className={styles.text}>monthly</p>
                        </div>
                    </div>

                    <div className={styles.card + ' ' + styles.annual}>
                        <div className={styles.borderContent}>
                            <img src={Icon} className={styles.icon} />

                            <p className={styles.price}>70 USD</p>
                            <p className={styles.description}><Trans>include all system no extra<br/> payment needed</Trans></p>
                            <div className={styles.bottom}>
                                <img src={Next} style={{visibility: 'hidden'}} />
                                <p onClick={onPay} className={styles.pay}>{t("click to pay")}</p>
                                <img onClick={onPay} src={Next} className={styles.next} />
                            </div>
                        </div>
                        <div className={styles.badge}>
                            <p className={styles.text}>annual</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default HowWorksPay