import styles from './styles.module.scss'
import img from '../../assets/img/Group 67683.png'
import { useTranslation, Trans } from "react-i18next";

function Best() {
    const { t, i18n } = useTranslation();

    return ( 
        <section className={styles.section}>
            <div className="container">
                <div className={styles.content}>
                    <img src={img} alt="" />
                    <div className={styles.textContent}>
                        <p className={styles.title}><Trans>Why we are the Best</Trans></p>
                        <p className={styles.text}><Trans>We are constantly improving our platform in line with your feedback and suggestions. Your contributions are invaluable in improving our services and making your experience even better.</Trans></p>
                        <p className={styles.text}><Trans>Our commitment to innovation drives us to explore new possibilities and bring you the best possible service sharing experience.</Trans></p>
                    </div>
                </div>
            </div>
        </section>
     );
}

export default Best;