import loader from '../../assets/img/preloader.gif'
import styles from './styles.module.scss'

function Preloader() {
    return ( 
        <div className={styles.content}>
            <img src={loader} alt="loader" style={{width: '200px', height: '200px'}} />
        </div>
     );
}

export default Preloader;