import icon1 from '../assets/img/servicesIcons/vacuum.svg'
import icon2 from '../assets/img/servicesIcons/balloons_86318 1.svg'
import icon3 from '../assets/img/servicesIcons/scales_balance_icon_206201 1.svg'
import icon4 from '../assets/img/servicesIcons/calculator.svg'
import icon5 from '../assets/img/servicesIcons/fitness_icon_235067 1.svg'
import icon6 from '../assets/img/servicesIcons/dumbbell_filled_icon_201414 1.svg'
import icon7 from '../assets/img/servicesIcons/big-cargo-truck_icon-icons.com_68588 1.svg'
import icon8 from '../assets/img/servicesIcons/book_icon-icons.com_73655 1.svg'
import icon9 from '../assets/img/servicesIcons/renovate_icon_132034 1.svg'
import icon10 from '../assets/img/servicesIcons/construction_engineering_helmet_cog_icon_153230 1.svg'
import icon11 from '../assets/img/servicesIcons/car_transport_vehicle_icon_123456 1.svg'
import icon12 from '../assets/img/servicesIcons/1486071980-1_79325 1.svg'
import icon13 from '../assets/img/servicesIcons/hairdresserscissorsandcomb_89167 1.svg'
import icon14 from '../assets/img/servicesIcons/photocamerablacktool_79818 1.svg'
import icon15 from '../assets/img/servicesIcons/cpu_fill_icon_185711 1.svg'
import icon16 from '../assets/img/servicesIcons/warehouse_icon_137039 1.svg'
import icon17 from '../assets/img/servicesIcons/construction_hammer_tool_icon_153213 1.svg'
import icon18 from '../assets/img/servicesIcons/construction_machine_crane_icon_153254 1.svg'


export const servicesAll = [
    {
        id: 1,
        icon: icon1,
        title: 'Cleaning',
        shortLinks: [
            {
                id: 1,
                text: 'House Cleaning',
                objects: '5 673',
            },
            {
                id: 2,
                text: 'Office Cleaning',
                objects: '123',
            },
            {
                id: 3,
                text: 'After renovation',
                objects: '123',
            },
            {
                id: 4,
                text: 'Deep cleaning',
                objects: '123',
            }
        ]
    },
    {
        id: 2,
        icon: icon2,
        title: 'Ceremony',
        shortLinks: [
            {
                id: 1,
                text: 'Wedding halls',
                objects: '5 673',
            },
            {
                id: 2,
                text: 'Musicans',
                objects: '123',
            },
            {
                id: 3,
                text: 'Mourning halls',
                objects: '123',
            },
            {
                id: 4,
                text: 'Wedding cars',
                objects: '123',
            }
        ]
    },
    {
        id: 3,
        icon: icon3,
        title: 'Legal',
        shortLinks: [
            {
                id: 1,
                text: 'Legal Advisor',
                objects: '123',
            },
            {
                id: 2,
                text: 'Corporate lawyer',
                objects: '123',
            },
            {
                id: 3,
                text: 'Real Estate',
                objects: '123',
            }
        ]
    },
    {
        id: 4,
        icon: icon4,
        title: 'Accounting',
        shortLinks: [
            {
                id: 1,
                text: 'Financial Advisor',
                objects: '123',
            },
            {
                id: 2,
                text: 'Accountant',
                objects: '123',
            },
            {
                id: 3,
                text: 'Stock advisor',
                objects: '123',
            }
        ]
    },
    {
        id: 5,
        icon: icon5,
        title: 'Health',
        shortLinks: [
            {
                id: 1,
                text: 'Nurses',
                objects: '123',
            },
            {
                id: 2,
                text: 'Pharmacy',
                objects: '123',
            },
            {
                id: 3,
                text: 'Emergency',
                objects: '123',
            },
            {
                id: 4,
                text: 'Clinics',
                objects: '5 673',
            }
        ]
    },
    {
        id: 6,
        icon: icon6,
        title: 'Sport',
        shortLinks: [
            {
                id: 1,
                text: 'GYM',
                objects: '123',
            },
            {
                id: 2,
                text: 'Body-building',
                objects: '123',
            },
            {
                id: 3,
                text: 'Boxing',
                objects: '5 673',
            },
            {
                id: 4,
                text: 'Swiming pool',
                objects: '5 673',
            },
        ]
    },
    {
        id: 7,
        icon: icon7,
        title: 'Transport',
        shortLinks: [
            {
                id: 1,
                text: 'Home to Home',
                objects: '5 673',
            },
            {
                id: 2,
                text: 'Office to Office',
                objects: '123',
            },
            {
                id: 3,
                text: 'In Country',
                objects: '123',
            },
            {
                id: 4,
                text: 'Out of Country',
                objects: '123',
            }
        ]
    },
    {
        id: 8,
        icon: icon8,
        title: 'Private lesson',
        shortLinks: [
            {
                id: 1,
                text: 'Maths',
                objects: '123',
            },
            {
                id: 2,
                text: 'English',
                objects: '123',
            },
            {
                id: 3,
                text: 'Germany',
                objects: '123',
            },
            {
                id: 4,
                text: 'Spanish',
                objects: '123',
            }
        ]
    },
    {
        id: 9,
        icon: icon9,
        title: 'Repair',
        shortLinks: [
            {
                id: 1,
                text: 'Home',
                objects: '123',
            },
            {
                id: 2,
                text: 'Office',
                objects: '123',
            },
            {
                id: 3,
                text: 'Country house',
                objects: '123',
            },
        ]
    },
    {
        id: 10,
        icon: icon10,
        title: 'Building',
        shortLinks: [
            {
                id: 1,
                text: 'Home',
                objects: '123',
            },
            {
                id: 2,
                text: 'Office',
                objects: '123',
            },
            {
                id: 3,
                text: 'Designer',
                objects: '123',
            },
            {
                id: 4,
                text: 'Planner',
                objects: '123',
            }
        ]
    },
    {
        id: 11,
        icon: icon11,
        title: 'Car repair',
        shortLinks: [
            {
                id: 1,
                text: 'Maintenance',
                objects: '123',
            },
            {
                id: 2,
                text: 'Tire service',
                objects: '123',
            },
            {
                id: 3,
                text: 'Motor Mechanic',
                objects: '123',
            },
            {
                id: 4,
                text: 'Spare parts',
                objects: '5 673',
            }
        ]
    },
    {
        id: 12,
        icon: icon12,
        title: 'Restaurants',
        shortLinks: [
            {
                id: 1,
                text: 'Fast Food',
                objects: '123',
            },
            {
                id: 2,
                text: 'Bistro',
                objects: '123',
            },
            {
                id: 3,
                text: 'Family',
                objects: '5 673',
            }
        ]
    },
    {
        id: 13,
        icon: icon13,
        title: 'Beauty',
        shortLinks: [
            {
                id: 1,
                text: 'Barbarshop',
                objects: '5 673',
            },
            {
                id: 2,
                text: 'Stylist',
                objects: '123',
            },
            {
                id: 3,
                text: 'Mail art',
                objects: '123',
            }
        ]
    },
    {
        id: 14,
        icon: icon14,
        title: 'Photograph',
        shortLinks: [
            {
                id: 1,
                text: 'Wedding',
                objects: '123',
            },
            {
                id: 2,
                text: 'Spasial days',
                objects: '123',
            },
            {
                id: 3,
                text: 'Birthday party',
                objects: '123',
            }
        ]
    },
    {
        id: 15,
        icon: icon15,
        title: 'Digital',
        shortLinks: [
            {
                id: 1,
                text: 'Developer',
                objects: '123',
            },
            {
                id: 2,
                text: 'Mobile APP',
                objects: '123',
            },
            {
                id: 3,
                text: 'Digital printing',
                objects: '123',
            }
        ]
    },
    {
        id: 16,
        icon: icon16,
        title: 'Warehouse',
        shortLinks: [
            {
                id: 1,
                text: 'Construction',
                objects: '123',
            },
            {
                id: 2,
                text: 'Equipment',
                objects: '123',
            },
            {
                id: 3,
                text: 'Parking lot',
                objects: '123',
            },
            {
                id: 4,
                text: 'Refrigerated',
                objects: '123',
            }
        ]
    },
    {
        id: 17,
        icon: icon17,
        title: 'Worker',
        shortLinks: [
            {
                id: 1,
                text: 'Handyman',
                objects: '123',
            },
            {
                id: 2,
                text: 'Plasterer',
                objects: '123',
            },
            {
                id: 3,
                text: 'Builder',
                objects: '123',
            },
            {
                id: 4,
                text: 'Loader',
                objects: '123',
            }
        ]
    },
    {
        id: 18,
        icon: icon18,
        title: 'Heavy machine',
        shortLinks: [
            {
                id: 1,
                text: 'Trackers',
                objects: '5 673',
            },
            {
                id: 2,
                text: 'Excavators',
                objects: '123',
            },
            {
                id: 3,
                text: 'Trailers',
                objects: '5 673',
            }
        ]
    }
]