import PrivacyHeader from '../Components/PrivacyHeader';
import useGetPrivacy from '../Hooks/useGetPrivacy';
import { useEffect } from 'react';
import { useTranslation, Trans } from "react-i18next";
import { ColorRing } from 'react-loader-spinner'

function Privacy({onChangeLogin, onChangeRegistration}) {
    const [privacy, getPrivacy] = useGetPrivacy();
    const { t, i18n } = useTranslation();

    useEffect(() => {
        getPrivacy(i18n.language);
    }, [i18n.language])

    useEffect(() => {
        window.scrollTo({top: 0, behavior: 'smooth'});
    }, [])

    return ( 
        <>
            <PrivacyHeader onChangeLogin={onChangeLogin} onChangeRegistration={onChangeRegistration}/>
            

            {privacy === '' && (
                <div className='container' style={{minHeight: '50vh', padding: "80px 10px"}}>
                    <ColorRing
                        visible={true}
                        height="80"
                        width="80"
                        ariaLabel="blocks-loading"
                        wrapperStyle={{}}
                        wrapperClass="blocks-wrapper"
                        colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                    />
                </div>
            )}

            {privacy !== '' && (
                <div className='container' style={{minHeight: '50vh', padding: "80px 10px"}} dangerouslySetInnerHTML={{__html: privacy}}>
                    
                </div>
            )}
        </>
     );
}

export default Privacy;