import styles from './styles.module.scss'
import { adverts } from '../../data/adverts';
import { useTranslation, Trans } from "react-i18next";

function NewsFull() {
    const { t, i18n } = useTranslation();

    return (
        <section className={styles.section}>
            <div className="container">
                <div className={styles.news}>
                    <div className={styles.cols}>
                        <div className={styles.col}>
                            {
                                adverts.map(item => {
                                    return (
                                        <div key={item.id} className={styles.slide} style={{ background: `url(${item.bg})` }}>
                                            <p className={styles.title}>{item.title}</p>
                                            <p className={styles.text}>{item.text}</p>
                                            <p className={styles.date}>{item.date}</p>
                                            <div className={styles.iconBlock}>
                                                <img src={item.icon} alt={item.iconText} />
                                                <p className={styles.iconText}>{item.iconText}</p>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className={styles.col}>
                            {
                                adverts.map(item => {
                                    return (
                                        <div key={item.id} className={styles.slide} style={{ background: `url(${item.bg})` }}>
                                            <p className={styles.title}>{item.title}</p>
                                            <p className={styles.text}>{item.text}</p>
                                            <p className={styles.date}>{item.date}</p>
                                            <div className={styles.iconBlock}>
                                                <img src={item.icon} alt={item.iconText} />
                                                <p className={styles.iconText}>{item.iconText}</p>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className={styles.col}>
                            {
                                adverts.map(item => {
                                    return (
                                        <div key={item.id} className={styles.slide} style={{ background: `url(${item.bg})` }}>
                                            <p className={styles.title}>{item.title}</p>
                                            <p className={styles.text}>{item.text}</p>
                                            <p className={styles.date}>{item.date}</p>
                                            <div className={styles.iconBlock}>
                                                <img src={item.icon} alt={item.iconText} />
                                                <p className={styles.iconText}>{item.iconText}</p>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>

                </div>
                <button className={styles.button}>{t("More")}</button>
            </div>
        </section>
    );
}

export default NewsFull;