import DownloadHeader from '../Components/DownloadHeader';
import { useEffect } from 'react';
import {BrowserView, MobileView} from 'react-device-detect';

function Download({toggleDownloadModalState, onChangeLogin, onChangeRegistration}) {

    useEffect(() => {
        window.scrollTo({top: 0, behavior: 'smooth'});
    }, [])

    return ( 
        <>
            <DownloadHeader  onChangeLogin={onChangeLogin} onChangeRegistration={onChangeRegistration}/>
            <BrowserView>
                <div
                    style={{
                        height: 100,
                    }}
                >
                </div>
            </BrowserView>
        </>
     );
}

export default Download;