import styles from './styles.module.scss'
import { useEffect, useRef, useState } from 'react'

function CodeInput({resend, setNaxtStep, onReset}) {

    const [code, changeCode] = useState('')

    const [dots, setDots] = useState([
        {
            id: 1,
            value: ''
        },
        {
            id: 2,
            value: ''
        },
        {
            id: 3,
            value: ''
        },
        {
            id: 4,
            value: ''
        }
    ])

    const [focused, setFocused] = useState(false)

    const inputRef = useRef();

    useEffect(() => {
        if(code.length === 4) {
            setNaxtStep(dots)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [code])

    useEffect(() => {
        if(resend === true) {
            changeCode('')
            setDots([
                {
                    id: 1,
                    value: ''
                },
                {
                    id: 2,
                    value: ''
                },
                {
                    id: 3,
                    value: ''
                },
                {
                    id: 4,
                    value: ''
                }
            ])
            onReset();
        }
    }, [resend])

    return (
        <div className={styles.code} onClick={() => {
            setFocused(true)
            inputRef.current.focus();
        }}>
            <div className={styles.dots}>
                {
                    dots.map(item => {
                        return (
                            <div className={styles.item} key={item.id}>
                                {
                                    <div className={item.id === code.length + 1 ? styles.focus : styles.block}>
                                        {item.value === '' && item.id === code.length + 1 && (<span className={styles.line} />)}
                                        {item.value !== '' && (<p>{item.value}</p>)}
                                        {item.value === '' && item.id !== code.length + 1 && (<span style={{visibility: 'hidden'}} className={styles.line} />)}
                                    </div>
                                }
                            </div>
                        )
                    })
                }
            </div>
            <input type="text" value={code} onBlur={() => setFocused(false)} ref={inputRef} onChange={(e) => {
                if(e.target.value.length <= 7) {
                    changeCode(e.target.value)
                    setDots(
                        dots.map((letter, i) => {
                            if (e.target.value[i]) {
                                letter.value = e.target.value[i]
                            } else {
                                letter.value = ''
                            }
                            return letter
                        })
                    )
                }
            }} />
        </div>
    );
}

export default CodeInput;