export default {
    "More than 800 services are<br/><2>waiting for you.</2>": "800-dən çox xidmət<br/><2>sizi gözləyir.</2>",
    "Nearby": "Yaxında",
    "Find": "Axtar",
    "Your safety and trust are our top priorities.": "Etibarınız və təhlükəsizliyiniz bizim əsas prioritetimizdir.",
    "We help you make informed decisions, provide a rating and review system based on the experiences of other users.": "Biz digər istifadəçilərin təcrübələrinə əsaslanan reytinq və nəzərdən keçirmə sistemi təqdim etməklə əsaslandırılmış qərarlar qəbul etməyə kömək edirik.",
    "With BURABAK, you can experience the comfort of knowing that your service needs are in competent hands.": "BURABAK ilə xidmət ehtiyaclarınızın səlahiyyətli əllərdə olduğuna əmin ola bilərsiniz.",
    "At BURBAK, we are passionate about ": "BURABAK-da biz fərdlərin",
    "transforming the way individuals and businesses access services. Our mission is to create a more sustainable and connected world by promoting resource sharing and community building.": "və müəssisələrin xidmətlərə çıxış yollarını dəyişdirməkdə həvəsliyik. Bizim missiyamız resurs mübadiləsini və icma quruculuğunu təşviq etməklə daha davamlı və əlaqəli dünya yaratmaqdır.",
    "We believe everyone should have easy access to the resources and expertise they need, regardless of traditional barriers.": "İnanırıq ki, ənənəvi maneələrdən asılı olmayaraq hər kəs ehtiyac duyduğu resurslara və biliyə asan çıxış əldə etməlidir.",
    "With BURABAK, you can access a wide variety of services to suit your needs. If you need transportation, repair and maintenance, personal care or any other service, our platform will help you.": "BURABAK ilə siz ehtiyaclarınıza uyğun geniş çeşiddə xidmətlərdən istifadə edə bilərsiniz. İstər nəqliyyata, təmirə və texniki xidmətə, şəxsi qulluğa və ya hər hansı digər xidmətə ehtiyacınız olmasından asılı olmayaraq, platformamız sizi təmin edir.",
    "<0>Revolutionizing Service-Sharing</0><br/>for a Connected World.": "<0>Əlaqəli dünyada</0><br/>Xidmət inqilabı",
    "about us by numbers": "rəqəmlərlə haqqımızda",
    "Professionals": "Peşəkarlar",
    "Services": "Xidmətlər",
    "Customers": "Müştərilər",
    "Simplicity": "Sadəlik",
    "Our user-friendly platform allows you to connect with service providers in your area with just a few clicks.": "Bizim istifadəçi dostu platformamız sizə bir neçə kliklə ərazinizdə və ya ondan kənarda xidmət təminatçıları ilə əlaqə saxlamağa şərait yaradır.",
    "Costs": "Xərclər",
    "We understand that cost is important, which is why our service providers offer competitive prices.": "Biz başa düşürük ki, xidmət qiymətləri vacibdir və buna görə də Peşəkarlarlarımız rəqabətə uygun qiymətlər təklif edirlər.",
    "Location": "Расположение",
    "With our location-based search, it's easy to find someone nearby for help.": "Məkan axtarışından istifadə edərək, yaxınlıqdakı Peşəkarları asanlıqla tapa və kömək istəyə bilərsiniz.",
    "<0>User  </0>Agreement.": "<0>İstifadəçi  </0>müqaviləsi.",
    "Why we are the Best": "Niyə biz ən yaxşıyıq",
    "We are constantly improving our platform in line with your feedback and suggestions. Your contributions are invaluable in improving our services and making your experience even better.": "Rəy və təklifləriniz əsasında platformamızı daim təkmilləşdiririk. Xidmətlərimizin təkmilləşdirilməsində və təcrübənizin daha da yaxşılaşdırılmasında töhfələriniz əvəzsizdir.",
    "Our commitment to innovation drives us to explore new possibilities and bring you the best possible service sharing experience.": "Yeniliklərə sadiqliyimiz bizi yeni imkanları kəşf etməyə və sizə ən yaxşı xidmət mübadiləsi təcrübəsini təqdim etməyə sövq edir.",
    "Get in touch": "Bizimlə əlaqə saxlayın",
    "Contact form": "Əlaqə forması",
    "Full name": "Tam adınız",
    "Enter your name": "Adınızı daxil edin",
    "Enter your full name": "Tam adınızı daxil edin",
    "Phone": "Telefon",
    "E-mail": "E-mail",
    "Enter your E-mail": "E-mail adresinizi daxil edin",
    "Message": "Mesaj",
    "Enter your message or question": "Mesajınızı və ya sualınızı daxil edin",
    "Send message": "Mesaj göndər",
    "INFO": "ИНФО",
    "About us": "Haqqımızda",
    "Contact us": "Bizimlə əlaqə saxlayın",
    "How it work?": "Necə işləyir?",
    "ADDITIONAL": "ƏLAVƏ",
    "Support": "Dəstək",
    "Privacy Policy": "Məxfilik Siyasəti",
    "FAQ": "FAQ",
    "User Agreement": "İstifadəçi müqaviləsi",
    "TO REACH US": "BİZİMLƏ ƏLAQƏ",
    "All Rights Reserved.": "Bütün hüquqlar qorunur.",
    "Sign In": "Giriş",
    "Sign Up": "Qeydiyyat",
    "Sign Out": "Çıxış",
    "How does this work": "Necə işləyir",
    "About": "Haqqımızda",
    "Login": "Giriş",
    "Join us": "Qeydiyyat",
    "What do you want?": "Nə axtarırsan?",
    "Where?": "Harada?",
    "Write your needs in a few words": "Tələblərinizi bir neçə sözlə yazın",
    "See experts on demand": "Tələb üzrə ekspertlərə baxın",
    "Compare prices and choose": "Qiymətləri müqayisə edin və seçin",
    "Join BURBAK today": "Bu gün BURABAK-a qoşulun",
    "Join BURABAK today and embark on a transformative journey. Experience the convenience, affordability, and sense of connection that service sharing brings. Together we can shape a brighter future where resources are shared, communities thrive and sustainable living is at hand.": "Bu gün BURABAK-a qoşulun və Xidmət mübadiləsinin gətirdiyi rahatlığı, sərfəliliyi və əlaqə hissini yaşayın. Biz birlikdə resursların paylaşıldığı, icmaların çiçəkləndiyi və dayanıqlı həyatın yaxın olduğu daha parlaq gələcəyi formalaşdıra bilərik.",
    "Join": "Qoşulun",
    "Free and without obligation.": "Pulsuz və öhdəliksiz.",
    "With BURABAK you will find the best experts in your field, whatever your needs.": "BURABAK ilə ehtiyaclarınız nə olursa olsun, öz sahənizdə ən yaxşı mütəxəssisləri tapacaqsınız.",
    "Register": "Qeydiyyat",
    "More suitable in app": "Tətbiqdə daha çox funksiya",
    "Download": "Yüklə",
    "Enter your phone number": "Telefon nömrənizi daxil edin",
    "Password": "Şifrə",
    "Enter your password": "Şifrənizi daxil edin",
    "I forgot password": "Şifrəni unutdum",
    "Hide": "Gizlət",
    "Show": "Göstər",
    "More": "Daha",
    "Popular Categories": "Populyar Kateqoriyalar",
    "Are you professional?": "Peşəkarsan?",
    "BURABAK Your partner for finding new customers online": "BURABAK İnternetdə yeni müştərilər tapmaq üçün tərəfdaşınızdır.",
    "Details": "Ətraflı",
    "An expert for every need": "Hər ehtiyac üçün mütəxəssis",
    "By clicking Continue, I accept the <1>Membership Terms</1>.": "Davam et düyməsini klikləməklə, <1>İstifadəçi Müqaviləsi</1>-ni qəbul edirəm.",
    "Send code": "Kod göndər",
    "An SMS with a confirmation code has been sent to your phone number. Enter it in the field below and click 'Continue'": "Telefon nömrənizə təsdiq kodu olan SMS göndərildi. Onu aşağıdakı sahəyə daxil edin və `Davam et` düyməsini basın",
    "Send SMS": "SMS göndər",
    "Surname": "Soyadı",
    "Man": "Kişi",
    "Woman" : "Qadın",
    "Next": "Davam et",
    "<0>Privacy  </0>Policy.": "<0>Məxfilik   </0>Siyasəti.",
    "<0>HOW IT  </0><br/>Work?": "<0>NECƏ </0><br/>İŞLƏYİR?",
    "welcome_professional": "Siz peşəkarsınız? Part-time işə ehtiyacınız varmı? İş axtarırsınız? Onda bizə gəlin. Qeydiyyatdan keçin və formu doldurun. Sizin istəyinizlə, yaxınlıqda və ya başqa bir şəhərdə iş sifarişləri görünəcək. Cavab verə, razılaşa və işə başlaya bilərsiniz. Yalnız nominal aylıq və ya illik abunə haqqı ödəyin və istədiyiniz zaman işləyin və ya evdə oturub sifariş axtarın.",
    "welcome_customer": "Biz sizə əsaslandırılmış qərarlar qəbul etməyə kömək edirik. Digər istifadəçilərin təcrübələrinə əsaslanan reytinq və nəzərdən keçirmə sistemi təqdim edirik. BURABAK ilə xidmətinizin səlahiyyətli əllərdə olduğuna əmin ola bilərsiniz. Sadəcə şərtləri göstərən bir iş tələbi qeyd edin və ən yaxın mütəxəssis cavab verəcəkdir. Bu xidmət üçün heç bir məbləğ tələb etmirik və siz özünüz peşəkarlarla verəcəyi xidmət haqda razılaşa bilərsiniz.",
    "click to pay": "ödəmək üçün klikləyin",
    "include all system no extra<br/> payment needed": "Hamisi bir arada sistemi əlavə<br/> ödəniş tələb etmir",
    "Download our mobile application": "Mobil tətbiqimizi yükləyin",
    "Scan the QR code and download our mobile app": "QR kodunu skan edin və mobil tətbiqimizi yükləyin",
    "Sneak peek1": "Layihəmizlə tanış olmağı sizi dəvət edirik!",
    "Sneak peek2": "Funksiyalar tezliklə vebsayta yeni həyat verəcəklər.",
    "Sneak peek5": "Daxil olun, əməkdaşlıq edin, xəbərləri izləyin və bizimlə fikirlərinizi paylaşın.",
    "Sneak peek3": "web saytımıza bax",
    "Sneak peek4": "GƏL",
    "OK": "OK",
    "Create an account here": "Burada hesab yaradın",
    "Invalid Code!": "Yanlış kod!",
    "Please Input Phone Number": "Telefon nömrəsini daxil edin",
    "Please Input Password": "Lütfən, parol daxil edin.",
    "Resend in": "Yenidən göndər",
    "Password Length Must Be Greater Than 8.": "Parolun uzunluğu 8-dən çox olmalıdır.",
    "Please Check Your Network Status": "Lütfən, şəbəkə statusunuzu yoxlayın",
    "User Already Exists": "İstifadəçi artıq mövcuddur",
    "Your phone number": "Telefon nömrəniz",
    "One more time": "Bir daha",
    "New Password": "Yeni Şifrə",
    "Go": "İrəli",
    "User Not Exists": "İstifadəçi mövcud deyil",
    "Sign Up Success": "Uğurlu qeydiyyat",
    "Please confirm your password.": "Lütfən, Şifrənizi təsdiqləyin.",
    "Success": "Uğurlu",
    "By clicking Continue, I accept the <1>User Agreement</1> and <3>Privacy Policy</3>.": "Davam et üzərinə klikləməklə, mən <1>İstifadəçi Müqaviləsi</1> və <3>Məxfilik Siyasəti</3> qəbul edirəm.",
    "Please Input Full Name": "Lütfən, tam adınızı daxil edin",
    "Please Input Sur Name": "Lütfən, soyadızı daxil edin",
    "Please Input Password One More Time": "Lütfən, Şifrənizi bir daha daxil edin",
    "House Cleaning": "Evin təmizliyi",
    "Office Cleaning": "Ofis təmizliyi",
    "After renovation": "Təmirdən sonra",
    "Deep cleaning": "Dərin təmizləmə",
    "Cleaning": "Təmizləmə",
    "Ceremony": "Mərasim",
    "Wedding halls": "Şadlıq sarayı",
    "Musicans": "Musiqiçilər",
    "Mourning halls": "Mərasim zalı",
    "Wedding cars": "Toy maşınları",
    "Legal": "Hüquqi",
    "Legal Advisor":"Hüquq məsləhətçisi",
    "Corporate lawyer":"Şirkət hüquqşünas",
    "Real Estate":"Daşınmaz əmlak",
    "Accounting":"Mühasibat",
    "Financial Advisor":"Maliyyə məsləhətçisi",
    "Accountant":"Mühasib",
    "Stock advisor":"Fond məsləhətçisi",
    "Health":"Sağlamlıq",
    "Nurses":"Tibb bacıları",
    "Pharmacy":"Əczanə",
    "Emergency":"Təcili yardım",
    "Clinics":"Klinikalar",
    "Sport":"İdman",
    "GYM":"İdman zalı",
    "Body-building":"Body-building",
    "Boxing":"Boks",
    "Swiming pool":"Üzgüçülük",
    "Transport":"Nəqliyyat",
    "Home to Home":"Evdən evə",
    'Office to Office':'Ofisdən ofisə',
    'In Country':'Ölkə içi',
    'Out of Country':'Ölkə xaricinə',
    'Private lesson':'Özəl dərs',
    'Maths':'Riyaziyyat',
    'English':'İngilis dili',
    'Germany':'Almanca',
    'Spanish':'İspan',
    'Repair':'Təmir',
    'Home':'Ev',
    'Office':'Ofis',
    'Country house':'İstirahət evi',
    'Building':'Tikinti',
    'Designer':'Dizayner',
    'Planner':'Eksteryer',
    'Car repair':'Avtomobil təmiri',
    'Maintenance':'Tex. xidmət',
    'Tire service':'Təkər xidməti',
    'Motor Mechanic':'Avtomexanik',
    'Spare parts':'Ehtiyat hissələri',
    'Restaurants':'Restoranlar',
    'Fast Food':'Fast food',
    'Bistro':'Bistro',
    'Family':'Ailəvi',
    'Beauty': 'Gözəllik',
    'Barbarshop':'Bərbər',
    'Stylist':'Stilist',
    'Mail art':'Manikür',
    'Photograph':'Fotoqraf',
    'Wedding':'Toy',
    'Spasial days':'Partilər',
    'Birthday party':'Ad günləri',
    'Digital':'Rəqəmsal',
    'Developer':'Developer',
    'Mobile APP':'Mobil proqram',
    'Digital printing':'Rəqəmsal çap',
    'Warehouse':'Anbar',
    'Construction':'Tikinti',
    'Equipment':'Avadanlıq',
    'Parking lot':'Parkovka',
    'Refrigerated':'Soyuducu',
    'Worker':'İşçi',
    'Handyman':'Fəhlə',
    'Plasterer':'Sıvaqçı',
    'Builder':'İnşaatçı',
    'Loader':'Yükləyici',
    'Heavy machine':'Ağır texnika',
    'Trackers':'Traktor',
    'Excavators':'Ekskavator',
    'Trailers':'Qoşqu',
    "Customer":"Müştəri",
    "Executor":"İcraçı",
    "Leave an order with a task description.": "Sifarişin təsviri ilə sorğu verin",
    "Executers will write themselves. We will  Show to suitable executor. They will write if they are willing to help.":"İcraçılar özləri yazacaqlar. Biz bunu uyğun İcraçıya göstərəcəyik. Kömək etməyə hazır olsalar yazacaqlar.",
    "Choose the right one, agree with him on all the details.":"Sizə uyğun olanı İcraçını seçin və birbaşa onunla razılaşın.",
    "Choose suitable task for you.":"Sizin üçün uyğun tapşırığı seçin.",
    "Write if you are willing to help and write to customer.": "Sifarişi qəbul etməyə və kömək etməyə hazırsınızsa, müştəriyə yazın.",
    "Agree on all the details with a customer.":"Bütün detalları müştəri ilə razılaşın.",
    "Signing...":"Daxil olur...",
    "Sign In Success": "Uğurlu avtorizasiya",
    "Welcome back":"Xoş gəlmisiniz",
    "Password entry rules:":"Parol daxil etmə qaydaları:",
    "Minimum of 8 characters":"Minimum 8 simvol",
    "1 capital letter":"1 böyük hərf",
    "1 lowercase letter":"1 kiçik hərf",
    "Minimum 1 digit":"Minimum 1 rəqəm",
    "Forgot Password?": "ifrənizi unutmusunuz?",
    "Remember me": "Məni xatırla",
    "Please fill name field.": "Lütfən, ad sahəsini doldurun.",
    "Please fill phone number field.":"Lütfən, telefon nömrəsi sahəsini doldurun.",
    "Please fill e-mail field.":"Lütfən, e-poçt sahəsini doldurun.",
    "Please fill message field.":"Lütfən, mesaj sahəsini doldurun.",

    // new words 30.09.2023
    "Home cleaning": "Ev təmizliyi",
    "Worker Plaster": "İşçi suvaqçı",
    "Load carrier": "Yük daşıyıcısı",
    "English teacher": "İngilis dili müəllimi",
    "Nurse": "Tibb bacısı",
    "Crane": "Kran",
    "Office cleaning": "Ofis təmizliyi",
    "Accounting consultant": "Mühasibat məsləhətçisi",
    "Vegetarian Restaurant": "Vegetarian Restoranı",
    "Lawyer Consultant": "Vəkil Məsləhətçi",

    // advert texts
    "Adverts": "Reklamlar",

    "<0>Download our</0><br/>Application.": "<0>TƏTBİQİMİZİ</0><br/>yükləyin.",
}