import styles from './styles.module.scss'
import icon1 from '../../assets/img/Group 717171.svg'
import icon2 from '../../assets/img/Group 7171.svg'
import icon3 from '../../assets/img/Group 71.svg'
import { useTranslation, Trans } from "react-i18next";

function AboutPluses() {
    const { t, i18n } = useTranslation();

    return ( 
        <section className={styles.section}>
            <div className="container">
                <div className={styles.items}>
                    <div className={styles.block}>
                        <img src={icon1} alt="" />
                        <p className={styles.title}><Trans>Simplicity</Trans></p>
                        <p className={styles.text}><Trans>Our user-friendly platform allows you to connect with service providers in your area with just a few clicks.</Trans></p>
                    </div>
                    <div className={styles.block}>
                        <img src={icon2} alt="" />
                        <p className={styles.title}><Trans>Costs</Trans></p>
                        <p className={styles.text}><Trans>We understand that cost is important, which is why our service providers offer competitive prices.</Trans></p>
                    </div>
                    <div className={styles.block}>
                        <img src={icon3} alt="" />
                        <p className={styles.title}><Trans>Location</Trans></p>
                        <p className={styles.text}><Trans>With our location-based search, it's easy to find someone nearby for help.</Trans></p>
                    </div>
                </div>
            </div>
        </section>
     );
}

export default AboutPluses;