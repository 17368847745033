import img from '../../assets/img/572d5971b283ce1ed28da761b88c717a 2.png'
import styles from './styles.module.scss'
import { useTranslation, Trans } from "react-i18next";

function AboutBanner() {
    const { t, i18n } = useTranslation();

    return ( 
        <section className={styles.section}>
            <div className="container">
                <div className={styles.content}>
                    <h3 className={styles.title}><Trans>Your safety and trust are our top priorities.</Trans></h3>
                    <p className={styles.text}><Trans>We help you make informed decisions, provide a rating and review system based on the experiences of other users.</Trans></p>
                    <p className={styles.text}><Trans>With BURABAK, you can experience the comfort of knowing that your service needs are in competent hands.</Trans></p>
                </div>
                <img src={img} alt="phone" className={styles.mainImage}/>
            </div>
        </section>
     );
}

export default AboutBanner;