import img from '../../assets/img/572d5971b283ce1ed28da761b88c717a 2.png'
import styles from './styles.module.scss'
import { Link } from 'react-router-dom';
import { useTranslation, Trans } from "react-i18next";

function HowWorkSwitch({value}) {
    const { t, i18n } = useTranslation();

    return ( 
        <section className={styles.section}>
            <div className="container">
                <div className={styles.content}>
                    <Link to="/how-works" className={value ? styles.active : styles.normal}>{t("Customer")}</Link>
                    <Link to="/professional" className={!value ? styles.active : styles.normal}>{t("Executor")}</Link>
                </div>
            </div>
        </section>
     );
}

export default HowWorkSwitch;