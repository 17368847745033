import { bigCategories, categories } from '../../data/categories';
import SectionTitle from '../SectionTitle';
import styles from './styles.module.scss'
import { useTranslation, Trans } from "react-i18next";

function PopularCategories() {
    const { t, i18n } = useTranslation();

    return (
        <section className={styles.section}>
            <div className="container">
                <SectionTitle text={t('Popular Categories')}/>
                <div className={styles.bigCategories}>
                    <div className={styles.content}>
                        {
                            bigCategories.map(item => {
                                return (
                                    <div key={item.id} className={styles.item}>
                                        <img className={styles.img} src={item.bg} alt={t(item.text)} />
                                        <p className={styles.text}>{t(item.text)}</p>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div className={styles.smallCategories}>
                    <div className={styles.content}>
                        {
                            categories.map(item => {
                                return (
                                    <div key={item.id} className={styles.item}>
                                        <img className={styles.img} src={item.bg} alt={t(item.text)} />
                                        <p className={styles.text}>{t(item.text)}</p>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </section>
    );
}

export default PopularCategories;