import ContactSection from "../Components/ContactsSection";
import ContactsSocials from "../Components/ContactsSocials";
import HeaderTop from "../Components/Header/HeaderTop";
import { useEffect } from "react";

function Contacts({ onChangeLogin, onChangeRegistration }) {

    useEffect(() => {
        window.scrollTo({top: 0, behavior: 'smooth'});
    }, [])

    return (
        <>
            <HeaderTop viewType={'map'} onChangeLogin={onChangeLogin} onChangeRegistration={onChangeRegistration} />
            <ContactSection/>
            <ContactsSocials/>
        </>
    );
}

export default Contacts;