import SectionTitle from "../SectionTitle";
import img1 from '../../assets/img/3af36875baf261b93c763309c313e6e2 1.png'
import img2 from '../../assets/img/ac4505b1e8acb04a3fa611af3d2bae9f 1.png'
import img3 from '../../assets/img/8d43bde3d1ceb40488eb6590f6322834 1.png'
import { useTranslation, Trans } from "react-i18next";

import styles from './styles.module.scss'

function HowWork({value}) {
    const { t, i18n } = useTranslation();

    return (
        <section className={styles.section} id="howWork" style={{marginTop: value === 'home' ? 90 : 0}}>
            <div className="container">
                <SectionTitle text={t('How does this work')} />
                <div className={styles.steps}>
                    <div className={styles.step}>
                        <p className={styles.number}>1</p>
                        {value === 'home' && (<p className={styles.text}>{t("Write your needs in a few words")}</p>)}
                        {value === 'customer' && (<p className={styles.text}>{t('Leave an order with a task description.')}</p>)}
                        {value === 'executor' && (<p className={styles.text}>{t('Choose suitable task for you.')}</p>)}
                        <img src={img1} alt="1 step"/>
                    </div>
                    <div className={styles.step}>
                        <p className={styles.number}>2</p>
                        {value === 'home' && (<p className={styles.text}>{t("See experts on demand")}</p>)}
                        {value === 'customer' && (<p className={styles.text}>{t('Executers will write themselves. We will  Show to suitable executor. They will write if they are willing to help.')}</p>)}
                        {value === 'executor' && (<p className={styles.text}>{t('Write if you are willing to help and write to customer.')}</p>)}
                        <img src={img2} alt="2 step"/>
                    </div>
                    <div className={styles.step}>
                        <p className={styles.number}>3</p>
                        {value === 'home' && (<p className={styles.text}>{t("Compare prices and choose")}</p>)}
                        {value === 'customer' && (<p className={styles.text}>{t('Choose the right one, agree with him on all the details.')}</p>)}
                        {value === 'executor' && (<p className={styles.text}>{t('Agree on all the details with a customer.')}</p>)}
                        <img src={img3} alt="3 step"/>
                    </div>
                </div>
                {value === 'customer' && (<p className={styles.welcome}>{t('welcome_customer')}</p>)}
                {value === 'executor' && (<p className={styles.welcome}>{t('welcome_professional')}</p>)}
                
            </div>
        </section>
    );
}

export default HowWork;