import React from 'react'
import styles from './styles.module.scss'
import LangIcon from '../LangIcon'

class Dropdown extends React.Component {
    constructor(props) {
      super(props);
     
      this.state = {
        open: false,
        selected: this.props.initial || -1
      };
    }
    
    toggleDropdown() {
      this.setState({
        active: !this.state.active
      });
    }
    
    renderOptions() {
      if (!this.props.options) {
        return;
      }
      
      return this.props.options.map((option, i) => {      
        return (
          <li 
            onClick={evt => {this.toggleDropdown(); this.props.handleClick(option.action)}} 
            key={i} 
            style={{display: 'flex', alignItems: 'center'}}
            className={styles.item + (i === this.state.selected ? styles.active : '')}
          >
            <p className={styles.menuItem}>{String(option.name)}</p>
          </li>
        );  
      });  
    }
    
    render() {
      return (
        <div className={styles.dropdown} style={this.props.selfStyle && { right: '0px', top: '30px'}}>
          <div
            onClick={() => this.toggleDropdown()}
            style={{display: 'flex', alignItems: 'center'}}
            className={styles.item}
          >
            <div className={styles.user}>
                <div className={styles.avatar}>
                    <p className={styles.nickName}>{String(this.props.user.name).substring(0, 1)}</p>
                </div>
                <p className={styles.name}>{String(this.props.user.name)}</p>
            </div>
            
            {this.props.dropdown && (
              <svg style={{marginLeft: '5px', transform: 'rotate(180deg)'}} xmlns="http://www.w3.org/2000/svg" width="8" height="5" viewBox="0 0 8 5" fill="none">
                <path d="M7.05333 5L4 1.90958L0.94 5L0 4.04858L4 0L8 4.04858L7.05333 5Z" fill="white"/>
              </svg>
            )}
          </div>
          {this.props.dropdown && (
            <ul style={{background: 'white', borderRadius: '10px'}} className={styles.dropdown__list + ' '+ (this.state.active ? styles.active : '')}>{this.renderOptions()}</ul>      
          )}
          
        </div>
      );
    }
  }

export default Dropdown