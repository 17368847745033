import styles from './styles.module.scss'
import { useState } from 'react';
import close from '../../../assets/img/Close.svg'
import bg from '../../../assets/img/download.png'
import phone from '../../../assets/img/Group 473784.png'

import social1 from '../../../assets/img/play_store.png'
import social2 from '../../../assets/img/app_store.png'
import social3 from '../../../assets/img/app_gallery.png'

import { useTranslation, Trans } from "react-i18next";
import QRCode from "react-qr-code";
import constants from "../../../Hooks/constants";

function DownloadModal({onClose}) {
    const [type, setType] = useState('password');
    const { t, i18n } = useTranslation();

    return ( 
        <div className={styles.modal}>
            <span className={styles.bg} onClick={() => onClose(true)} />

            <div className={styles.content}>                
                <div className={styles.card}>
                    <img src={bg} className={styles.card_bg} />
                    <img src={phone} className={styles.phone} />
                    <div className={styles.body}>
                        <h2 className={styles.title}>{t("Download our mobile application")}</h2>
                        <div className={styles.row}>
                            <a target="_blank" href={constants.google_store()}>
                                <img className={styles.icon} src={social1} alt="" />
                            </a>
                            <a target="_blank" href={constants.apple_store()}>
                                <img className={styles.icon} src={social2} alt="" />
                            </a>
                        </div>
                        <div className={styles.row}>
                            <a target="_blank" href={constants.gallery_store()}>
                                <img className={styles.icon} src={social3} alt="" />
                            </a>
                        </div>
                    </div>
                    
                    <button onClick={() => onClose(false)} className={styles.closeBtn}>
                        <img src={close} alt="X" />
                    </button>
                </div>

                {/* <div className={styles.card}>
                    <img src={bg} className={styles.card_bg} />
                    <button onClick={() => onClose(false)} className={styles.closeBtn}>
                        <img src={close} alt="X" />
                    </button>
                    <img src={phone} className={styles.phone} />
                    <div className={styles.body}>
                        <h2 className={styles.title}>{t("Scan the QR code and download our mobile app")}</h2>

                        <div className={styles.qrContainer}>
                            <QRCode value="Download" size={200} />
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
     );
}

export default DownloadModal;