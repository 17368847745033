import styles from './styles.module.scss'
import icon1 from '../../assets/img/AboutSocialsIcons/facebook.svg'
import icon2 from '../../assets/img/AboutSocialsIcons/instagram.svg'
import icon3 from '../../assets/img/AboutSocialsIcons/youtube.svg'
import icon4 from '../../assets/img/AboutSocialsIcons/twitter.svg'
import icon5 from '../../assets/img/AboutSocialsIcons/pinterest.svg'

function ContactsSocials() {

    return ( 
        <section className={styles.section}>
            <div className="container">
                <div className={styles.content}>
                    <a target="_blank" href='https://www.facebook.com/burabakaze'>
                        <img src={icon1} alt="" />
                    </a>
                    <a target="_blank" href='https://www.instagram.com/burabakaze'>
                        <img src={icon2} alt="" />
                    </a>
                    <a target="_blank" href='https://www.youtube.com/@Burabakcom'>
                        <img src={icon3} alt="" />
                    </a>
                    <a target="_blank" href='https://twitter.com/BurabakGroup'>
                        <img src={icon4} alt="" />
                    </a>
                </div>
            </div>
        </section>
     );
}

export default ContactsSocials;