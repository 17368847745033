import React from 'react'
import Drawer from 'react-modern-drawer'
import styles from './styles.module.scss'
import { NavLink } from 'react-router-dom';
import { useTranslation, Trans } from "react-i18next";
import LangSelect from '../../LangDropDown';
import Profile from '../../Profile'

const DrawerContent = ({onProfileMenu, Auth, isOpen, toggleDrawer, onChangeLogin, onChangeRegistration}) => {

    const { t, i18n } = useTranslation();

    const changeLanguage = lng => {
        i18n.changeLanguage(lng);
    };

    const isUser = () => {
        return !(Auth.user === null || Auth.user.payload === null || Auth.user.payload === undefined || Auth.user.payload === '')
    }

    return (
        <div className={styles.drawer}>
            <Drawer
                open={isOpen}
                onClose={toggleDrawer}
                direction='left'
                className={styles.drawerContent}
                size={300}
                style={{background: '#BBCE5E'}}
            >
                <div className={styles.container}>
                    <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', width: '100%'}}>
                        <button className={styles.back} onClick={toggleDrawer}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M20 12.2744C20 12.6541 19.7178 12.9679 19.3518 13.0176L19.25 13.0244L4.25 13.0244C3.83579 13.0244 3.5 12.6886 3.5 12.2744C3.5 11.8947 3.78215 11.5809 4.14823 11.5313L4.25 11.5244L19.25 11.5244C19.6642 11.5244 20 11.8602 20 12.2744Z" fill="#3A3A3A"/>
                                <path d="M10.829 17.7675C11.1225 18.0598 11.1235 18.5347 10.8313 18.8282C10.5656 19.095 10.149 19.1201 9.85489 18.9029L9.77062 18.8305L3.72062 12.8065C3.45298 12.54 3.42863 12.1219 3.64759 11.8278L3.72057 11.7436L9.77057 5.71857C10.0641 5.42629 10.5389 5.42727 10.8312 5.72077C11.0969 5.98759 11.1203 6.4043 10.9018 6.69746L10.829 6.78143L5.313 12.2753L10.829 17.7675Z" fill="#3A3A3A"/>
                            </svg>
                        </button><br/>
                        {
                            !(Auth.user === null || Auth.user.payload === null || Auth.user.payload === undefined || Auth.user.payload === '') && (
                                <Profile dropdown={false} handleClick={(e) => onProfileMenu(e)} user={Auth.user.payload} options={[ {name: 'Sign Out', action:'sign_out'} ]} />
                            )
                        }                        
                    </div>
                    <LangSelect selfStyle={true} handleClick={changeLanguage} title={i18n.language} options={['en', 'az', 'ru', 'tr', 'ar', 'ge']} />
                    
                    <NavLink to="/how-works" className={styles.navItem}>{t("How it work?")}</NavLink>
                    <div className={styles.line}></div>
                    <NavLink to="/about-us" className={styles.navItem}>{t("About us")}</NavLink>
                    <div className={styles.line}></div>
                    {!isUser() && (
                        <>
                            <button onClick={() => onChangeLogin(true)} className={styles.signin}>{t("Sign In")}</button>
                            <button onClick={() => onChangeRegistration(true)} className={styles.signin}>{t("Sign Up")}</button>
                        </>
                    )}
                    {isUser() && (
                        <button onClick={() => onProfileMenu('sign_out')} className={styles.signin}>{t("Sign Out")}</button>
                    )}
                </div>
            </Drawer>
        </div>
    )
}

export default DrawerContent;