export default {
    "More than 800 services are<br/><2>waiting for you.</2>": "Более 800 услуг<br/><2>ждут Вас.</2>",
    "Nearby": "Рядом",
    "Find": "Найти",
    "Your safety and trust are our top priorities.": "Ваша безопасность и доверие - наши главные приоритеты.",
    "We help you make informed decisions, provide a rating and review system based on the experiences of other users.": "Мы помогаем вам принимать взвешенные решения, предоставляя систему рейтингов и отзывов, основанную на опыте других пользователей.",
    "With BURABAK, you can experience the comfort of knowing that your service needs are in competent hands.": "С компанией BURABAK вы можете быть уверены в том, что ваши сервисные потребности находятся в компетентных руках.",
    "At BURBAK, we are passionate about ": "В компании BURABAK мы с энтузиазмом ",
    "transforming the way individuals and businesses access services. Our mission is to create a more sustainable and connected world by promoting resource sharing and community building.": "относимся к преобразования способов доступа к услугам для частных лиц и предприятий. Наша миссия - создать более устойчивый и взаимосвязанный мир, способствуя обмену ресурсами и созданию сообществ.",
    "We believe everyone should have easy access to the resources and expertise they need, regardless of traditional barriers.": "Мы считаем, что каждый человек должен иметь легкий доступ к необходимым ему ресурсам и знаниям, независимо от традиционных барьеров.",
    "With BURABAK, you can access a wide variety of services to suit your needs. If you need transportation, repair and maintenance, personal care or any other service, our platform will help you.": "С помощью BURABAK вы можете получить доступ к широкому спектру услуг, отвечающих вашим потребностям. Если вам нужен транспорт, ремонт и обслуживание, персональный уход или любая другая услуга, наша платформа поможет вам.",
    "<0>Revolutionizing Service-Sharing</0><br/>for a Connected World.": "<0>Революция в обслуживании</0><br/>в подключенном мире.",
    "about us by numbers": "о нас в цифрах",
    "Professionals": "Профессионалы",
    "Services": "Услуги",
    "Customers": "Клиенты",
    "Simplicity": "Простота",
    "Our user-friendly platform allows you to connect with service providers in your area with just a few clicks.": "Наша удобная платформа позволяет всего за несколько щелчков мыши связаться с поставщиками услуг в вашем регионе или за его пределами.",
    "Costs": "Затраты",
    "We understand that cost is important, which is why our service providers offer competitive prices.": "Мы понимаем, что стоимость услуг имеет большое значение, поэтому наши поставщики услуг предлагают конкурентоспособные цены.",
    "Location": "Расположение",
    "With our location-based search, it's easy to find someone nearby for help.": "С помощью поиска по местоположению можно легко найти человека, находящегося поблизости, чтобы получить помощь.",
    "<0>User  </0>Agreement.": "<0>Пользователь  </0>Соглашение.",
    "Why we are the Best": "Почему мы являемся лучшими",
    "We are constantly improving our platform in line with your feedback and suggestions. Your contributions are invaluable in improving our services and making your experience even better.": "Мы постоянно совершенствуем нашу платформу в соответствии с вашими отзывами и предложениями. Ваш вклад неоценим для улучшения наших услуг и повышения качества обслуживания.",
    "Our commitment to innovation drives us to explore new possibilities and bring you the best possible service sharing experience.": "Наша приверженность инновациям заставляет нас исследовать новые возможности и предоставлять вам наилучший опыт совместного использования услуг.",
    "Get in touch": "Свяжитесь с нами",
    "Contact form": "Контактная форма",
    "Full name": "Полное имя",
    "Enter your name": "Введите ваше имя",
    "Enter your full name": "Введите ваше полное имя",
    "Phone": "Телефон",
    "E-mail": "E-mail",
    "Enter your E-mail": "Введите свой E-mail",
    "Message": "Сообщение",
    "Enter your message or question": "Введите ваше сообщение или вопрос",
    "Send message": "Отправить сообщение",
    "INFO": "ИНФО",
    "About us": "О нас",
    "Contact us": "Свяжитесь с нами",
    "How it work?": "Как это работает?",
    "ADDITIONAL": "ДОПОЛНИТЕЛЬНО",
    "Support": "Поддержка",
    "Privacy Policy": "Политика конфиденциальности",
    "FAQ": "FAQ",
    "User Agreement": "Пользовательское соглашение",
    "TO REACH US": "СВЯЗАТЬСЯ С НАМИ",
    "All Rights Reserved.": "Все права защищены.",
    "Sign In": "Войти",
    "Sign Up": "Регистрация",
    "Sign Out": "Выход",
    "How does this work": "Как это работает",
    "About": "О нас",
    "Login": "Войти",
    "Join us": "Регистрация",
    "What do you want?": "Что вы ищете?",
    "Where?": "Где?",
    "Write your needs in a few words": "Напишите свои требования в нескольких словах",
    "See experts on demand": "Посмотреть экспертов по запросу",
    "Compare prices and choose": "Сравните цены и выберите",
    "Join BURBAK today": "Присоединяйтесь к BURABAK уже сегодня",
    "Join BURABAK today and embark on a transformative journey. Experience the convenience, affordability, and sense of connection that service sharing brings. Together we can shape a brighter future where resources are shared, communities thrive and sustainable living is at hand.": "Присоединяйтесь к BURABAK сегодня и отправляйтесь в путешествие. Ощутите удобство, доступность и чувство связи, которые дает совместное использование услуг. Вместе мы сможем сформировать светлое будущее, в котором ресурсы будут общими, сообщества - процветающими, а жизнь - устойчивой.",
    "Join": "Присоединяйся",
    "Free and without obligation.": "Бесплатно и без обязательств.",
    "With BURABAK you will find the best experts in your field, whatever your needs.": "С помощью BURABAK вы найдете лучших специалистов в своей области, независимо от ваших потребностей.",
    "Register": "Регистрация",
    "More suitable in app": "Больше в приложении",
    "Download": "Скачать",
    "Enter your phone number": "Введите номер телефона",
    "Password": "Пароль",
    "Enter your password": "Введите пароль",
    "I forgot password": "Я забыл пароль",
    "Hide": "Скрыть",
    "Show": "Показать",
    "More": "Больше",
    "Popular Categories": "Популярные категории",
    "Are you professional?": "Вы профессионал?",
    "BURABAK Your partner for finding new customers online": "BURABAK Ваш партнер по поиску новых клиентов в Интернете",
    "Details": "Детали",
    "An expert for every need": "Специалист на все случаи жизни",
    "By clicking Continue, I accept the <1>Membership Terms</1>.": "Нажимая кнопку Продолжить, я принимаю <1>Пользовательское соглашение</1>.",
    "Send code": "Отправить код",
    "An SMS with a confirmation code has been sent to your phone number. Enter it in the field below and click 'Continue'": "На Ваш номер телефона отправлено SMS-сообщение с кодом подтверждения. Введите его в поле ниже и нажмите кнопку 'Продолжить'",
    "Send SMS": "Отправить SMS",
    "Surname": "Фамилия",
    "Man": "Мужчина",
    "Woman" : "Женщина",
    "Next": "Далее",
    "<0>Privacy  </0>Policy.": "<0>Политика   </0>конфиденциальности.",
    "<0>HOW IT  </0><br/>Work?": "<0>КАК ЭТО  </0><br/>РАБОТАЕТ?",
    "welcome_professional": "Вы профессионал? Вам нужна работа на неполный рабочий день? Вы ищете работу? Тогда вам к нам. Зарегистрируйтесь и заполните форму. На Ваше усмотрение будут появляться заказы или заявки на работу рядом или в другом городе. Вы можете откликнуться, договориться и приступить к работе. Платите только ежемесячную или ежегодную символическую абонентскую плату и работайте, когда хотите, или сидите дома и ищите заказ.",
    "welcome_customer": "Мы помогаем вам принимать взвешенные решения и предоставляем систему рейтингов и отзывов, основанную на опыте других пользователей. С BURABAK вы можете быть уверены, что ваш сервис находится в компетентных руках. Просто оставьте заявку с указанием условий, и ближайший профессионал откликнется. Мы не требуем за эту услугу никакой суммы денег, и вы можете сами договориться об услуге профессионала.",
    "click to pay": "нажмите для оплаты",
    "include all system no extra<br/> payment needed": "система 'все в одном' не требует<br/> дополнительной оплаты",
    "Download our mobile application": "Скачайте наше мобильное приложение",
    "Scan the QR code and download our mobile app": "Отсканируйте QR-код и загрузите наше мобильное приложение",
    "Sneak peek1": "Загляните в душу нашего проекта.",
    "Sneak peek2": "Функции готовы проявить себя и вскоре украсят веб-сайт.",
    "Sneak peek5": "Погрузитесь, сотрудничайте, следите за новостями и делитесь со своими идеями.",
    "Sneak peek3": "к нам на наш сайт",
    "Sneak peek4": "Иди",
    "OK": "OK",
    "Create an account here": "Создайте аккаунт здесь",
    "Invalid Code!": "Неверный код!",
    "Please Input Phone Number": "Пожалуйста, введите номер телефона",
    "Please Input Password": "Пожалуйста, введите пароль.",
    "Resend in": "Отправить повторно",
    "Password Length Must Be Greater Than 8.": "Длина пароля должна быть больше, чем 8.",
    "Please Check Your Network Status": "Пожалуйста, проверьте состояние сети",
    "User Already Exists": "Пользователь уже существует",
    "Your phone number": "Ваш номер телефона",
    "One more time": "Еще раз",
    "New Password": "Новый пароль",
    "Go": "Вперед",
    "User Not Exists": "Пользователь не существует",
    "Sign Up Success": "Успешная регистрация",
    "Please confirm your password.": "Пожалуйста, подтвердите свой пароль.",
    "Success": "Удача",
    "By clicking Continue, I accept the <1>User Agreement</1> and <3>Privacy Policy</3>.": "Нажимая кнопку Продолжить, я принимаю <1>Пользовательское соглашение</1> и <3>Политику конфиденциальности</3>.",
    "Please Input Full Name": "Пожалуйста, введите полное имя",
    "Please Input Sur Name": "Пожалуйста, введите фамилию",
    "Please Input Password One More Time": "Пожалуйста, введите пароль еще раз",
    "House Cleaning": "Уборка дома",
    "Office Cleaning": "Уборка офиса",
    "After renovation": "После ремонта",
    "Deep cleaning": "Глубокая уборка",
    "Cleaning": "Уборка",
    "Ceremony": "Церемония",
    "Wedding halls": "Свадебные залы",
    "Musicans": "Музыканты",
    "Mourning halls": "Траурные залы",
    "Wedding cars": "Свадебные автомобили",
    "Legal": "Юридическая",
    "Legal Advisor":"Юр. консультант",
    "Corporate lawyer":"Корпоративный юрист",
    "Real Estate":"Недвижимость",
    "Accounting":"Бух. учет",
    "Financial Advisor":"Фин. консультант",
    "Accountant":"Бухгалтер",
    "Stock advisor":"Фондовый консультант",
    "Health":"Здоровье",
    "Nurses":"Медсестры",
    "Pharmacy":"Аптека",
    "Emergency":"Скорая помощь",
    "Clinics":"Клиники",
    "Sport":"Спорт",
    "GYM":"Спортзал",
    "Body-building":"Бодибилдинг",
    "Boxing":"Бокс",
    "Swiming pool":"Плавание",
    "Transport":"Транспорт",
    "Home to Home":"От дома к дому",
    'Office to Office':'Из офиса в офис',
    'In Country':'В странe',
    'Out of Country':'Вне страны',
    'Private lesson':'Частный урок',
    'Maths':'Математика',
    'English':'Английский',
    'Germany':'Немеццкий',
    'Spanish':'Испанский',
    'Repair':'Ремонт',
    'Home':'Дом',
    'Office':'Офис',
    'Country house':'Загородный дом',
    'Building':'Строительство',
    'Designer':'Дизайнер',
    'Planner':'Проектировщик',
    'Car repair':'Ремонт авто',
    'Maintenance':'Тех. обслуживание',
    'Tire service':'Шиномонтаж',
    'Motor Mechanic':'Автомеханик',
    'Spare parts':'Запчасти',
    'Restaurants':'Рестораны',
    'Fast Food':'Быстрое питание',
    'Bistro':'Бистро',
    'Family':'Семейный',
    'Beauty': 'Красота',
    'Barbarshop':'Парикмахерская',
    'Stylist':'Стилист',
    'Mail art':'Mаникюр',
    'Photograph':'Фотограф',
    'Wedding':'Свадьба',
    'Spasial days':'Вечеринки',
    'Birthday party':'День рождения',
    'Digital':'Цифровой',
    'Developer':'Разработчик',
    'Mobile APP':'Моб.приложение',
    'Digital printing':'Цифровая печать',
    'Warehouse':'Склад',
    'Construction':'Конструкция',
    'Equipment':'Оборудование',
    'Parking lot':'Парковка',
    'Refrigerated':'Холодильная',
    'Worker':'Рабочий',
    'Handyman':'Разнорабочий',
    'Plasterer':'Штукатуршик',
    'Builder':'Строитель',
    'Loader':'Погрузчик',
    'Heavy machine':'Тяжелая техника',
    'Trackers':'Тракторы',
    'Excavators':'Экскаваторы',
    'Trailers':'Прицепы',
    "Customer":"Заказчик",
    "Executor":"Исполнитель",
    "Leave an order with a task description.": "Оставить заявку с описанием задачи.",
    "Executers will write themselves. We will  Show to suitable executor. They will write if they are willing to help.":"Исполнители напишут сами. Мы покажем его подходящему исполнителю. Они напишут, готовы ли они помочь.",
    "Choose the right one, agree with him on all the details.":"Выберите подходящего и согласуйте с ним все детали.",
    "Choose suitable task for you.":"Выберите подходящую для вас задачу.",
    "Write if you are willing to help and write to customer.": "Напишите заказчику, готовы ли вы получить задание и помочь.",
    "Agree on all the details with a customer.":"Согласовать все детали с заказчиком.",
    "Signing...":"Входите ...",
    "Sign In Success": "Успешная авторизация",
    "Welcome back":"Возвращением",
    "Password entry rules:":"Правила ввода пароля:",
    "Minimum of 8 characters":"Минимум 8 символов",
    "1 capital letter":"1 заглавная буква",
    "1 lowercase letter":"1 строчная буква",
    "Minimum 1 digit":"Минимум 1 цифра",
    "Forgot Password?": "Забыли пароль?",
    "Remember me": "Запомнить меня",
    "Please fill name field.": "Пожалуйста, заполните поле имени.",
    "Please fill phone number field.":"Пожалуйста, заполните поле телефонного номера.",
    "Please fill e-mail field.":"Пожалуйста, заполните поле e-mail.",
    "Please fill message field.":"Пожалуйста, заполните поле сообщения.",

    // new words 30.09.2023
    "Home cleaning": "Уборка дома",
    "Worker Plaster": "Рабочий-штукатур",
    "Load carrier": "Грузоперевозчик",
    "English teacher": "Учитель Английского",
    "Nurse": "Медсестра",
    "Crane": "Кран",
    "Office cleaning": "Уборка офиса",
    "Accounting consultant": "Бухгалтерский консультант",
    "Vegetarian Restaurant": "Вегетарианский ресторан",
    "Lawyer Consultant": "Юрист-консультант",

    // advert texts
    "Adverts": "Объявления",

    "<0>Download our</0><br/>Application.": "<0>Скачайте наше</0><br/>ПРИЛОЖЕНИЕ.",
}