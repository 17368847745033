import { useNavigate } from 'react-router-dom';
import MoreButton from '../MoreBtn';
import SectionTitle from '../SectionTitle';
import AdvertSlider from './AdvertSlider';
import styles from './styles.module.scss'
import {useTranslation} from "react-i18next";

function AdvertSection() {
    const { t, i18n } = useTranslation();

    const navigate = useNavigate()

    return (
        <section className={styles.section}>
            <div className="container">
                <SectionTitle text={t('Adverts')} />
                <AdvertSlider />
                <span onClick={() => navigate('/news')}>
                    <MoreButton />
                </span>
            </div>
        </section>
    );
}

export default AdvertSection;