import React, { useEffect } from 'react'
import styles from './styles.module.scss'
import { NavLink } from 'react-router-dom';
import logo from '../../../assets/img/logo.svg'
import Drawer from './drawer'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation, Trans } from "react-i18next";
import LangSelect from '../../LangDropDown';
import Profile from '../../Profile'

import useGetCurrentUser from '../../../Hooks/useGetCurrentUser';
import {currentState} from '../../../redux/slices/auth'

import {
    setToken,
    setUser
} from '../../../redux/slices/auth';

const HeaderTop = ({viewType, onChangeLogin, onChangeRegistration}) => {
    const dispatch = useDispatch()
    const Auth = useSelector(currentState)
    const [isOpen, setIsOpen] = React.useState(false)
    const toggleDrawer = () => {
        setIsOpen((prevState) => !prevState)
    }
    const [currentUserResponse, getCurrentUser] = useGetCurrentUser();

    const { t, i18n } = useTranslation();

    const changeLanguage = lng => {
        i18n.changeLanguage(lng);
    };

    useEffect(() => {
        console.log(i18n.language)
        if(i18n.language === 'en-US') i18n.changeLanguage('en')
        console.log(Auth)
        if(Auth.user !== null && Auth.user.payload !== null && Auth.user.payload !== undefined && Auth.user.payload !== '') {
            getCurrentUser(Auth.token.payload, i18n.language)
        }
    }, [])

    useEffect(() => {
        if(currentUserResponse !== null) {
            if(!currentUserResponse.success) {
                dispatch(setToken(null))
                dispatch(setUser(null))
            }
        }
    }, [currentUserResponse])

    const onProfileMenu = (action) => {
        if(action === 'sign_out') {
            dispatch(setToken(null))
            dispatch(setUser(null))
        }
    }

    return (
        <div className={styles.headerTop} style={{ background: viewType === 'map' ? 'rgba(0, 0, 0, 0.6)' : '' }}>
            <div className="container">
                <div className={styles.topContent}>
                    <NavLink to="/" className={styles.heaerLogo}>
                        <img className={styles.logoImg} src={logo} alt="BURABAK" />
                    </NavLink>
                    <nav className={styles.headerNav}>
                        <NavLink to="/how-works" className={styles.navItem}>{t("How does this work")}</NavLink>
                        <NavLink to="/about-us" className={styles.navItem}>{t("About")}</NavLink>
                        {(Auth.user === null || Auth.user.payload === null || Auth.user.payload === undefined || Auth.user.payload === '') && (
                            <>
                                <button onClick={() => onChangeLogin(true)} className={styles.loginBtn}>{t("Login")}</button>
                                <button onClick={() => onChangeRegistration(true)} className={styles.joinBtn}>{t("Join us")}</button>
                            </>
                        )}
                        {
                            !(Auth.user === null || Auth.user.payload === null || Auth.user.payload === undefined || Auth.user.payload === '') && (
                                // <div className={styles.user}>
                                //     <div className={styles.avatar}>
                                //         <p className={styles.nickName}>{String(Auth.user.payload.name).substring(0, 1)}</p>
                                //     </div>
                                //     <p className={styles.name}>{String(Auth.user.payload.name)}</p>
                                // </div>
                                <Profile dropdown={true} handleClick={onProfileMenu} user={Auth.user.payload} options={[ {name: 'Sign Out', action:'sign_out'} ]} />
                            )
                        }
                        <LangSelect handleClick={changeLanguage} title={i18n.language} options={['en', 'az', 'ru', 'tr', 'ar', 'ge']} />
                    </nav>
                    <button className={styles.drawer} onClick={toggleDrawer}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="16" viewBox="0 0 24 16" fill="none">
                            <path d="M0 0H24V2.66667H0V0ZM0 6.66667H24V9.33333H0V6.66667ZM0 13.3333H24V16H0V13.3333Z" fill="white"/>
                        </svg>
                    </button>
                </div>
            </div>
            <Drawer isOpen={isOpen} Auth={Auth} onProfileMenu={onProfileMenu} toggleDrawer={toggleDrawer} onChangeLogin={onChangeLogin} onChangeRegistration={onChangeRegistration}/>
        </div>
    );
}

export default HeaderTop;