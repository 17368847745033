import styles from './styles.module.scss'
import { useTranslation, Trans } from "react-i18next";

const ic_search = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" style={{marginRight: 10}} width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M6.33333 2C7.4826 2 8.58481 2.45655 9.39746 3.2692C10.2101 4.08186 10.6667 5.18406 10.6667 6.33333C10.6667 7.40667 10.2733 8.39333 9.62667 9.15333L9.80667 9.33333H10.3333L13.6667 12.6667L12.6667 13.6667L9.33333 10.3333V9.80667L9.15333 9.62667C8.39333 10.2733 7.40667 10.6667 6.33333 10.6667C5.18406 10.6667 4.08186 10.2101 3.2692 9.39746C2.45655 8.58481 2 7.4826 2 6.33333C2 5.18406 2.45655 4.08186 3.2692 3.2692C4.08186 2.45655 5.18406 2 6.33333 2ZM6.33333 3.33333C4.66667 3.33333 3.33333 4.66667 3.33333 6.33333C3.33333 8 4.66667 9.33333 6.33333 9.33333C8 9.33333 9.33333 8 9.33333 6.33333C9.33333 4.66667 8 3.33333 6.33333 3.33333Z" fill="#3A3A3A" />
        </svg>
    )
}
const ic_gps = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M7.99967 4.33334C8.4417 4.33334 8.86562 4.50894 9.17819 4.8215C9.49075 5.13406 9.66634 5.55798 9.66634 6.00001C9.66634 6.21888 9.62323 6.43561 9.53947 6.63782C9.45572 6.84002 9.33295 7.02376 9.17819 7.17852C9.02342 7.33329 8.83969 7.45605 8.63748 7.53981C8.43527 7.62357 8.21854 7.66668 7.99967 7.66668C7.55765 7.66668 7.13372 7.49108 6.82116 7.17852C6.5086 6.86596 6.33301 6.44204 6.33301 6.00001C6.33301 5.55798 6.5086 5.13406 6.82116 4.8215C7.13372 4.50894 7.55765 4.33334 7.99967 4.33334ZM7.99967 1.33334C9.23735 1.33334 10.4243 1.82501 11.2995 2.70018C12.1747 3.57535 12.6663 4.76233 12.6663 6.00001C12.6663 9.50001 7.99967 14.6667 7.99967 14.6667C7.99967 14.6667 3.33301 9.50001 3.33301 6.00001C3.33301 4.76233 3.82467 3.57535 4.69984 2.70018C5.57501 1.82501 6.762 1.33334 7.99967 1.33334ZM7.99967 2.66668C7.11562 2.66668 6.26777 3.01787 5.64265 3.64299C5.01753 4.26811 4.66634 5.11595 4.66634 6.00001C4.66634 6.66668 4.66634 8.00001 7.99967 12.4733C11.333 8.00001 11.333 6.66668 11.333 6.00001C11.333 5.11595 10.9818 4.26811 10.3567 3.64299C9.73158 3.01787 8.88373 2.66668 7.99967 2.66668Z" fill="#3A3A3A" />
        </svg>
    )
}
const ic_menu = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M14.5 8C14.5 8.552 14.052 9 13.5 9H5.5C4.948 9 4.5 8.552 4.5 8C4.5 7.448 4.948 7 5.5 7H13.5C14.052 7 14.5 7.448 14.5 8Z" fill="#3A3A3A"/>
            <path d="M14.5 3C14.5 3.552 14.052 4 13.5 4H5.5C4.948 4 4.5 3.552 4.5 3C4.5 2.448 4.948 2 5.5 2H13.5C14.052 2 14.5 2.448 14.5 3Z" fill="#3A3A3A"/>
            <path d="M14.5 13C14.5 13.552 14.052 14 13.5 14H5.5C4.948 14 4.5 13.552 4.5 13C4.5 12.448 4.948 12 5.5 12H13.5C14.052 12 14.5 12.448 14.5 13Z" fill="#3A3A3A"/>
            <path d="M1.5 3C1.5 3.5515 1.9485 4 2.5 4C3.0515 4 3.5 3.5515 3.5 3C3.5 2.4485 3.0515 2 2.5 2C1.9485 2 1.5 2.4485 1.5 3Z" fill="#3A3A3A"/>
            <path d="M1.5 8C1.5 8.5515 1.9485 9 2.5 9C3.0515 9 3.5 8.5515 3.5 8C3.5 7.4485 3.0515 7 2.5 7C1.9485 7 1.5 7.4485 1.5 8Z" fill="#3A3A3A"/>
            <path d="M1.5 13C1.5 13.5515 1.9485 14 2.5 14C3.0515 14 3.5 13.5515 3.5 13C3.5 12.4485 3.0515 12 2.5 12C1.9485 12 1.5 12.4485 1.5 13Z" fill="#3A3A3A"/>
        </svg>
    )
}

function PlcePick({ onChange, value }) {
    const { t, i18n } = useTranslation();

    return (
        <div className={styles.content}>
            <div className={styles.input} style={{width: value === 'map' ? '60%' : ''}}>
                {ic_search()}
                <input type="text" placeholder={t("What do you want?")} />
            </div>
            {
                value === 'default' &&
                <>
                    <span className={styles.line}></span>
                    <div className={styles.input}>
                        {ic_gps()}
                        <input type="text" placeholder={t('Where?')} />
                    </div>
                </>
            }
            <div className={styles.toolbar}>
                {
                    value === 'default' ?
                        <button className={styles.pick} onClick={() => onChange('map')}>
                            {ic_gps()}
                        </button> : <button className={styles.pick} onClick={() => onChange('default')}>
                            {ic_menu()}
                        </button>
                }
                {
                    value === 'default' ? 
                    <button className={styles.search}>{ic_search()}<Trans>Nearby</Trans></button> :
                    <button className={styles.search}><Trans>Find</Trans></button>
                }
            </div>
        </div>
    );
}

export default PlcePick;