import React, { useEffect } from 'react';
import constants from '../Hooks/constants'

const RedirectScreen = () => {
    useEffect(() => {
        // Function to redirect based on the platform
        const handleRedirect = () => {
            const userAgent = navigator.userAgent || navigator.vendor || window.opera;

            if (/android/i.test(userAgent)) {
                // Redirect to Google Play Store for Android
                window.location.href = constants.google_store();
            } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                // Redirect to App Store for iOS
                window.location.href = constants.apple_store();
            } else {
                // Redirect to website for other devices
                window.location.href = 'https://burabak.com/download';
            }
        };

        handleRedirect(); // Execute redirect logic
    }, []);

    return (
        <div>
            <p>Redirecting...</p>
        </div>
    );
};

export default RedirectScreen;