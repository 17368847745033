import { miniTabs } from '../../data/newsMiniTabs';
import styles from './styles.module.scss'
import { useTranslation, Trans } from "react-i18next";

function NewsMminiTabs() {
    const { t, i18n } = useTranslation();
    
    return (
        <section className={styles.section}>
            <div className="container">
                <div className={styles.tabs}>
                    {
                        miniTabs.map(item => {
                            return (
                                <div style={{background: item.bgColor}} className={styles.tab} key={item.id}>
                                    <img src={item.icon} alt={t(item.value)} />
                                    <p>{t(item.value)}</p>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </section>
    );
}

export default NewsMminiTabs;