import styles from './styles.module.scss'
import img from '../../assets/img/Group 6768.png'
import { useTranslation, Trans } from "react-i18next";

function AboutBlock() {
    const { t, i18n } = useTranslation();

    return ( 
        <section className={styles.section}>
            <div className="container">
                <div className={styles.content}>
                    <img src={img} alt="" />
                    <div className={styles.textContent}>
                        <p className={styles.title}><Trans>At BURBAK, we are passionate about </Trans></p>
                        <p className={styles.text}><Trans>transforming the way individuals and businesses access services. Our mission is to create a more sustainable and connected world by promoting resource sharing and community building.</Trans></p>
                        <p className={styles.text}><Trans>We believe everyone should have easy access to the resources and expertise they need, regardless of traditional barriers.</Trans></p>
                        <p className={styles.text}><Trans>With BURABAK, you can access a wide variety of services to suit your needs. If you need transportation, repair and maintenance, personal care or any other service, our platform will help you.</Trans></p>
                    </div>
                </div>
            </div>
        </section>
     );
}

export default AboutBlock;