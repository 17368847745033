import img1 from '../assets/img/vacuum.svg'
import img2 from '../assets/img/renovate_icon_132034 1.svg'
import img3 from '../assets/img/construction_engineering_helmet_cog_icon_153230 1.svg'
import img4 from '../assets/img/car_transport_vehicle_icon_123456 1.svg'
import img5 from '../assets/img/construction_machine_crane_icon_153254 1.svg'
import img6 from '../assets/img/construction_hammer_tool_icon_153213 1.svg'
import img7 from '../assets/img/balloons_86318 1.svg'
import img8 from '../assets/img/scales_balance_icon_206201 1.svg'
import img9 from '../assets/img/calculator.svg'
import img10 from '../assets/img/fitness_icon_235067 1.svg'
import img11 from '../assets/img/hairdresserscissorsandcomb_89167 1.svg'
import img12 from '../assets/img/big-cargo-truck_icon-icons.com_68588 1.svg'

export const services = [
    {
        img: img1,
        text: 'Cleaning'
    },
    {
        img: img2,
        text: 'Repair'
    },
    {
        img: img3,
        text: 'Building'
    },
    {
        img: img4,
        text: 'Car repair'
    },
    {
        img: img5,
        text: 'Heavy machine'
    },
    {
        img: img6,
        text: 'Worker'
    },
    {
        img: img7,
        text: 'Ceremony'
    },
    {
        img: img8,
        text: 'Legal'
    },
    {
        img: img9,
        text: 'Accounting'
    },
    {
        img: img10,
        text: 'Health'
    },
    {
        img: img11,
        text: 'Beauty'
    },
    {
        img: img12,
        text: 'Transport'
    },
]