import icon1 from '../assets/img/fitness_icon_235067 1 mini.svg'
import icon2 from '../assets/img/balloons_86318 1 mini.svg'
import icon3 from '../assets/img/big-cargo-truck_icon-icons.com_68588 1 mini.svg'
import icon4 from '../assets/img/book_icon-icons.com_73655 1-1.svg'
import icon5 from '../assets/img/ticket.svg'
import icon6 from '../assets/img/renovate_icon_132034 1 mini.svg'

export const miniTabs = [
    {
        id: 1,
        icon: icon1,
        value: 'Health',
        bgColor: '#E7F5FF'
    },
    {
        id: 2,
        icon: icon2,
        value: 'Ceremony',
        bgColor: '#C7E5F9'
    },
    {
        id: 3,
        icon: icon3,
        value: 'Transport',
        bgColor: '#E0F1B4'
    },
    {
        id: 4,
        icon: icon4,
        value: 'Private lesson',
        bgColor: '#E2F9A8'
    },
    {
        id: 5,
        icon: icon5,
        value: 'Services',
        bgColor: '#D4E7A2'
    },
    {
        id: 6,
        icon: icon6,
        value: 'Repair',
        bgColor: '#BFD685'
    },
    {
        id: 7,
        icon: icon3,
        value: 'Transport',
        bgColor: '#FAF1B6'
    },
    {
        id: 8,
        icon: icon4,
        value: 'Private lesson',
        bgColor: '#FCEC83'
    },
]