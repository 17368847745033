import React from 'react'
import styles from './style.bundle.scss';
import LangIcon from '../LangIcon'

class Dropdown extends React.Component {
    constructor(props) {
      super(props);
     
      this.state = {
        open: false,
        selected: this.props.initial || -1
      };
    }
    
    toggleDropdown() {
      this.setState({
        active: !this.state.active
      });
    }
    
    renderOptions() {
      if (!this.props.options) {
        return;
      }
      
      return this.props.options.map((option, i) => {      
        return (
          <li 
            onClick={evt => {this.toggleDropdown(); this.props.handleClick(option)}} 
            key={i} 
            style={{display: 'flex', alignItems: 'center'}}
            className={"dropdown__list-item " + (i === this.state.selected ? 'dropdown__list-item--active' : '')}
          >
            {option.indexOf('en') >= 0 && option !== this.props.title && LangIcon.en()}
            {option.indexOf('az') >= 0 && option !== this.props.title && LangIcon.az()}
            {option.indexOf('ru') >= 0 && option !== this.props.title && LangIcon.ru()}
            {option.indexOf('tr') >= 0 && option !== this.props.title && LangIcon.tr()}
            {option.indexOf('ar') >= 0 && option !== this.props.title && LangIcon.ar()}
            {option.indexOf('ge') >= 0 && option !== this.props.title && LangIcon.ge()}
            {option !== this.props.title && (<p style={{marginRight: '15px'}}>{option.toUpperCase()}</p>)}
          </li>
        );  
      });  
    }
    
    render() {
      return (
        <div className="dropdown" style={this.props.selfStyle && { right: '0px', top: '30px'}}>
          <div
            onClick={() => this.toggleDropdown()}
            className="dropdown__toggle dropdown__list-item"
            style={{display: 'flex', alignItems: 'center'}}
          >
            {this.props.title.indexOf('en') >= 0 && LangIcon.en()}
            {this.props.title.indexOf('az') >= 0 && LangIcon.az()}
            {this.props.title.indexOf('ru') >= 0 && LangIcon.ru()}
            {this.props.title.indexOf('tr') >= 0 && LangIcon.tr()}
            {this.props.title.indexOf('ar') >= 0 && LangIcon.ar()}
            {this.props.title.indexOf('ge') >= 0 && LangIcon.ge()}
            
            <svg style={{marginLeft: '5px', transform: 'rotate(180deg)'}} xmlns="http://www.w3.org/2000/svg" width="8" height="5" viewBox="0 0 8 5" fill="none">
              <path d="M7.05333 5L4 1.90958L0.94 5L0 4.04858L4 0L8 4.04858L7.05333 5Z" fill="white"/>
            </svg>
          </div>
          <ul style={{background: 'white', borderRadius: '10px'}} className={"dropdown__list " + (this.state.active ? 'dropdown__list--active' : '')}>{this.renderOptions()}</ul>      
        </div>
      );
    }
  }

export default Dropdown