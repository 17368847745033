import React, { useState } from "react";
import DatePicker from "react-datepicker";
import styles from './styles.module.scss'

import "react-datepicker/dist/react-datepicker.css";


export const DatePickerComp = ({startDate, setStartDate}) => {

    const range = (start, end) => {
        var ans = [];
        for (let i = start; i <= end; i++) {
            ans.push(i);
        }
        return ans;
    }

    const years = range(1990, new Date().getFullYear() + 10);
    const months = [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December", ];

    return (
        <div className={styles.wrap}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path d="M6 6.66663V7.99996H4.66667V6.66663H6ZM8.66667 6.66663V7.99996H7.33333V6.66663H8.66667ZM11.3333 6.66663V7.99996H10V6.66663H11.3333ZM12.6667 1.99996C13.0203 1.99996 13.3594 2.14043 13.6095 2.39048C13.8595 2.64053 14 2.97967 14 3.33329V12.6666C14 13.0202 13.8595 13.3594 13.6095 13.6094C13.3594 13.8595 13.0203 14 12.6667 14H3.33333C2.59333 14 2 13.4 2 12.6666V3.33329C2 2.97967 2.14048 2.64053 2.39052 2.39048C2.64057 2.14043 2.97971 1.99996 3.33333 1.99996H4V0.666626H5.33333V1.99996H10.6667V0.666626H12V1.99996H12.6667ZM12.6667 12.6666V5.33329H3.33333V12.6666H12.6667ZM6 9.33329V10.6666H4.66667V9.33329H6ZM8.66667 9.33329V10.6666H7.33333V9.33329H8.66667ZM11.3333 9.33329V10.6666H10V9.33329H11.3333Z" fill="#899AA4" />
            </svg>
            <DatePicker
                renderCustomHeader={({
                    date,
                    changeYear,
                    changeMonth,
                    decreaseMonth,
                    increaseMonth,
                    prevMonthButtonDisabled,
                    nextMonthButtonDisabled,
                  }) => (
                    <div
                      style={{
                        margin: 10,
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <button className={styles.button} onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                        {"<"}
                      </button>
                      <select
                        value={date.getFullYear()}
                        onChange={({ target: { value } }) => changeYear(value)}
                        style={{marginRight: 10}}
                      >
                        {years.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
            
                      <select
                        value={months[date.getMonth()]}
                        onChange={({ target: { value } }) =>
                          changeMonth(months.indexOf(value))
                        }
                      >
                        {months.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
            
                      <button className={styles.button} onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                        {">"}
                      </button>
                    </div>
                  )}
                selected={startDate}
                onChange={(date) => setStartDate(date)}
            />
        </div>
    );
};