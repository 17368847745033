import styles from './styles.module.scss'
import { useTranslation, Trans } from "react-i18next";

function AboutInNumber() {
    const { t, i18n } = useTranslation();

    return ( 
        <section className={styles.section}>
            <div className="container">
                <h2 className={styles.title}><Trans>about us by numbers</Trans></h2>
                <div className={styles.content}>
                    <div className={styles.item}>
                        <h3 className={styles.value}>120 000</h3>
                        <p className={styles.text}><Trans>Professionals</Trans></p>
                    </div>
                    <div className={styles.item}>
                        <h3 className={styles.value}>800</h3>
                        <p className={styles.text}><Trans>Services</Trans></p>
                    </div>
                    <div className={styles.item}>
                        <h3 className={styles.value}>1 200 000</h3>
                        <p className={styles.text}><Trans>Customers</Trans></p>
                    </div>
                </div>
            </div>
        </section>
     );
}

export default AboutInNumber;