import HeaderTop from '../Header/HeaderTop';
import styles from './styles.module.scss';
import { useTranslation, Trans } from "react-i18next";

function PrivacyHeader({onChangeLogin, onChangeRegistration}) {
    const { t, i18n } = useTranslation();

    return ( 
        <div className={styles.content}>
            <HeaderTop viewType={'map'}  onChangeLogin={onChangeLogin} onChangeRegistration={onChangeRegistration}/>
            <div className="container">
                <h1 className={styles.title}><Trans><span>Privacy  </span>Policy.</Trans></h1>
            </div>
        </div>
     );
}

export default PrivacyHeader;