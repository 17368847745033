import styles from './styles.module.scss'
import img from '../../assets/img/pro.png'
import { useTranslation, Trans } from "react-i18next";
import { Link } from 'react-router-dom';

function Professional() {
    const { t, i18n } = useTranslation();

    return ( 
        <section className={styles.section}>
            <div className="container">
                <div className={styles.content}>
                    <h3 className={styles.title}>{t("Are you professional?")}</h3>
                    <p className={styles.text}>{t("BURABAK Your partner for finding new customers online")}</p>
                    <button className={styles.button}>
                        <Link to="/professional">{t("Details")}</Link>
                    </button>
                </div>
                <img src={img} alt="work" className={styles.mainImage}/>
            </div>
        </section>
     );
}

export default Professional;