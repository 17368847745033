import ic_en from '../../assets/img/EN.png'
import ic_az from '../../assets/img/AZ.png'
import ic_ru from '../../assets/img/RU.png'
import ic_ar from '../../assets/img/AR.png'
import ic_ge from '../../assets/img/GE.png'
import ic_tr from '../../assets/img/TR.png'

const en = () => <img width="30" height="30" src={ic_en}/>;
const az = () => <img width="30" height="30" src={ic_az}/>;
const ru = () => <img width="30" height="30" src={ic_ru}/>;
const tr = () => <img width="30" height="30" src={ic_tr}/>;
const ar = () => <img width="30" height="30" src={ic_ar}/>;
const ge = () => <img width="30" height="30" src={ic_ge}/>;

export default {
    en: en,
    az: az,
    ru: ru,
    tr: tr,
    ar: ar,
    ge: ge
}