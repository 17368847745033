import { Link } from 'react-router-dom';
import styles from './styles.module.scss'
import social1 from '../../assets/img/play_store.png'
import social2 from '../../assets/img/app_store.png'
import social3 from '../../assets/img/app_gallery.png'
import footerLogo from '../../assets/img/footerLogo.svg'

import link1 from '../../assets/img/facebook.svg'
import link2 from '../../assets/img/instagram.svg'
import link3 from '../../assets/img/youtube.svg'
import link4 from '../../assets/img/twitter.svg'
import link5 from '../../assets/img/pinterest.svg'

import bank1 from '../../assets/img/bank1.png'
import bank2 from '../../assets/img/bank2.png'
import bank3 from '../../assets/img/bank3.png'

import constants from '../../Hooks/constants';

import { useTranslation, Trans } from "react-i18next";
import useGetFAQ from '../../Hooks/useGetFAQ';
import { useEffect } from 'react';

function Footer() {
    const { t, i18n } = useTranslation();
    const [FAQ, getFAQ] = useGetFAQ()

    useEffect(() => {
        getFAQ(i18n.language)
    }, [i18n.language])

    return (
        <footer className={styles.footer}>
            <div className="container">
                <div className={styles.content}>
                    <div className={styles.cols}>
                        <div className={styles.col}>
                            <p className={styles.titleCol}>{t('INFO')}</p>
                            <Link to="/about-us" className={styles.footerLink}>{t('About us')}</Link>
                            <Link to={'/contacts'} className={styles.footerLink}>{t('Contact us')}</Link>
                            <Link to="/how-works" className={styles.footerLink}>{t('How it work?')}</Link>
                        </div>
                        <div className={styles.col}>
                            <p className={styles.titleCol}>{t("ADDITIONAL")}</p>
                            <Link to="/user-agreements" className={styles.footerLink}>{t("User Agreement")}</Link>
                            <div className={styles.row}>
                                <Link to="/privacy" className={styles.footerLink}>{t('Privacy Policy')}</Link>                            
                            </div>
                                <Link to="/faq" className={styles.footerLink}>{t('FAQ')}</Link>
                        </div>
                        <div className={styles.col}>
                            <p className={styles.titleCol}>{t("TO REACH US")}</p>
                            <div className={styles.row}>
                                <a target="_blank" href="mailto:hi@burabak.com" className={styles.mailLink}>hi@burabak.com</a>
                                <div className={styles.socials}>
                                    <a target="_blank" href='https://www.facebook.com/burabakaze'>
                                        <img src={link1} alt="" />
                                    </a>
                                    <a target="_blank" href='https://www.instagram.com/burabakaze'>
                                        <img src={link2} alt="" />
                                    </a>
                                    <a target="_blank" href='https://www.youtube.com/@Burabakcom'>
                                        <img src={link3} alt="" />
                                    </a>
                                    <a target="_blank" href='https://twitter.com/BurabakGroup'>
                                        <img src={link4} alt="" />
                                    </a>
                                </div>
                            </div>
                            <div className={styles.row}>
                                <a target="_blank" href={constants.google_store()}>
                                    <img className={styles.icon} src={social1} alt="" />
                                </a>
                                <a target="_blank" href={constants.apple_store()}>
                                    <img className={styles.icon} src={social2} alt="" />
                                </a>
                                <a target="_blank" href={constants.gallery_store()}>
                                    <img className={styles.icon} src={social3} alt="" />
                                </a>
                            </div>
                            <div className={styles.rowBank}>
                                <a target="_blank">
                                    <img className={styles.iconBank} src={bank1} alt="" />
                                </a>
                                <a target="_blank">
                                    <img className={styles.iconBank} src={bank2} alt="" />
                                </a>
                                <a target="_blank">
                                    <img className={styles.iconBank} src={bank3} alt="" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.footerBottom}>
                    <div className={styles.col}>
                        <img src={footerLogo} alt="" />
                        <p className={styles.year}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <path d="M6.71967 7.23992C6.75301 7.01992 6.82634 6.82659 6.91967 6.66659C7.11967 6.29325 7.45967 6.09992 7.91967 6.09325C8.21967 6.09325 8.49301 6.22659 8.68634 6.41992C8.87301 6.62659 8.99967 6.91325 8.99967 7.19992H10.1997C10.1863 6.88659 10.1263 6.59992 9.99967 6.33325C9.89967 6.07992 9.74634 5.85325 9.54634 5.66659C8.57967 4.77325 6.78634 4.89992 5.96634 5.91325C5.10634 7.02659 5.08634 8.97325 5.95967 10.0866C6.76634 11.0799 8.53301 11.2199 9.49301 10.3333C9.69967 10.1666 9.86634 9.95992 9.99967 9.71992C10.1063 9.47992 10.1797 9.22659 10.1863 8.95325H8.99967C8.99967 9.09325 8.95301 9.21992 8.89301 9.33325C8.83301 9.45992 8.75301 9.55992 8.66634 9.64659C8.44634 9.81992 8.18634 9.91325 7.90634 9.91325C7.66634 9.90659 7.46634 9.85992 7.31301 9.75992C7.14634 9.66659 6.99967 9.51325 6.91967 9.33325C6.58634 8.73325 6.63967 7.89992 6.71967 7.23992ZM7.99967 1.33325C4.33301 1.33325 1.33301 4.33325 1.33301 7.99992C1.68634 16.8466 14.333 16.8399 14.6663 7.99992C14.6663 4.33325 11.6663 1.33325 7.99967 1.33325ZM7.99967 13.3333C5.05967 13.3333 2.66634 10.9399 2.66634 7.99992C2.95967 0.926585 13.0397 0.926585 13.333 7.99992C13.333 10.9399 10.9397 13.3333 7.99967 13.3333Z" fill="white" />
                            </svg>
                            2011-2023
                        </p>
                        <p className={styles.policy}>{t("All Rights Reserved.")}</p>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;