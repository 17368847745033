import styles from './styles.module.scss'
import close from '../../../assets/img/Close.svg'
import { useEffect, useState, useRef } from 'react';
import CodeInput from './CodeInput';
import { DatePickerComp } from '../../DatePicker';
import { useTranslation, Trans } from "react-i18next";
import useCheckUser from '../../../Hooks/useCheckUser';
import useRegisterUser from '../../../Hooks/useRegisterUser';
import useCheckCode from '../../../Hooks/useCheckCode';
import toast from 'react-hot-toast';
import moment from 'moment/moment';
import {Link, useNavigate} from 'react-router-dom';
import { useDispatch } from 'react-redux'
import PhoneInput from 'react-phone-input-2'
import { ColorRing } from 'react-loader-spinner'

import useLoginUser from '../../../Hooks/useLoginUser';
import useGetCurrentUser from '../../../Hooks/useGetCurrentUser';

import {
    setToken,
    setUser
} from '../../../redux/slices/auth';
import constants from "../../../Hooks/constants";

function RegistrationModal({ toggleDownloadModalState, toggleLoginModal, onClose }) {
    const dispatch = useDispatch();
    const [iserror, setError] = useState(false)
    const [step, setStep] = useState(1);
    const [currentGender, changeGender] = useState('man')
    const [timer, setTimer] = useState(60);
    const [timerState, setStateTimer] = useState();
    const { t, i18n } = useTranslation();
    const [type, setType] = useState('password');
    const [phoneNumber, setPhoneNumber] = useState('')
    const [resend, setResend] = useState(false)
    const [fullName, setFullName] = useState('')
    const [srName, setSrName] = useState('')
    const [password, setPassword] = useState('')
    const [startDate, setStartDate] = useState(new Date());
    const [response, registerUser] = useRegisterUser();
    const [checkCodeResponse, checkCode] = useCheckCode();
    const [checkResponse, checkUser] = useCheckUser();

    const [loginResponse, loginUser] = useLoginUser();
    const [checkUserResponse, getCurrentUser] = useGetCurrentUser();
    const [signing, setSigning] = useState(false);
    const [loading, setLoading] = useState(false)

    const navigate = useNavigate()

    const _ref = useRef(null);

    const startTimer = () => {
        let seconds = 60;
        setStateTimer(setInterval(() => {
            seconds = seconds - 1;
            if (seconds > -1) {
                setTimer(seconds)
            } else {
                clearInterval(timerState);
            }
        }, 1000))
    }

    useEffect(() => {
        if(step === 2) {
            if(timerState) {
                clearInterval(timerState);
                setTimer(60)
            }
            startTimer()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [step])

    useEffect(() => {
        if(checkResponse !== null) {
            setLoading(false)
            if(checkResponse.userExists) {
                toast.error(t('User Already Exists'))
            } else if(checkResponse.success === false) {
                toast.error(checkResponse.message)
            }
            else {
                setStep(2);
            }
        }
    }, [checkResponse])

    useEffect(() => {
        if(step > 1) {
            setLoading(false)
            if(response === null) {
                toast.error(t('Please Check Your Network Status'))
            } else if(response.success !== true) {
                toast.error(response.message)
            } else {
                toast.success(t('Sign Up Success'))
                toast.success(t('Signing...'))

                loginUser({
                    "phone": phoneNumber,
                    "password": password,
                    "type": "password"
                }, i18n.language);

                setSigning(true);
            }
        }
    }, [response])

    useEffect(() => {
        if(signing) {
            if(loginResponse.success) {
                let token = `Bearer ${loginResponse.token}`;
                dispatch(setToken(token));

                getCurrentUser(token, i18n.language)
            } else {
                toast.error(loginResponse.message);
            }
        }
    }, [loginResponse])

    useEffect(() => {
        if(signing) {
            if(checkUserResponse.success) {
                dispatch(setUser(checkUserResponse.user));
                toast.success(t('Sign In Success'))
                navigate('/download')
                toggleDownloadModalState(true);
                onClose(false)
                setSigning(false);
            } else {
                toast.error(checkUserResponse.message);
            }
        }
    }, [checkUserResponse])

    const onSendCode = () => {
        if(phoneNumber === '') {
            setError(true);
            toast.error(t('Please Input Phone Number'))
        } else {
            setError(false);
            setLoading(true)
            checkUser(phoneNumber, i18n.language, true);
        }
    }

    const onReSendCode = () => {
        setResend(true)
        setError(false);
        checkUser(phoneNumber, i18n.language, true);

        if(timerState) {
            clearInterval(timerState);
            setTimer(60)
        }
        startTimer()
    }

    const onReset = () => {
        setResend(false)
    }

    const onCheck = (dots) => {
        var pin = "";
        for(let i = 0 ; i < 4 ; i++) pin += dots[i].value;
        if(checkResponse === null || pin !== String(checkResponse.code).substring(0, 4)) {
            setError(true)
        } else {
            setStep(3);
            setTimeout(() => {
                _ref.current.scrollIntoView({ bottom: 0, behavior: 'smooth' });
            }, 500);
        }
    }

    const onRegister = () => {
        if(fullName === '') {
            toast.error(t('Please Input Full Name'))
        } else if(srName === '') {
            toast.error(t('Please Input Sur Name'))
        } else if(password.length < 8) {
            toast.error(t("Password Length Must Be Greater Than 8."))
        } else {
            setLoading(true)
            var data = {
                "phone": phoneNumber,
                "password": password,
                "name": fullName,
                "surname": srName,
                "type": "password",
                "gender": currentGender,
                "birthday": moment(startDate).format('YYYY/MM/DD')
            }
            registerUser(data, i18n.language)
        }
    }

    return (
        <div className={styles.modal}>
            <span className={styles.bg} onClick={() => onClose(true)} />
            <div style={{maxHeight:'80vh', overflowY: 'auto'}}>
                <div className={styles.content}>
                    <button onClick={() => onClose(false)} className={styles.closeBtn}>
                        <img src={close} alt="X" />
                    </button>
                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <h2 className={styles.title}>{t("Sign Up")}</h2>
                        <h6 onClick={() => {onClose(false); toggleLoginModal(true)}} className={styles.smallTitle}>{t("Sign In")}</h6>
                    </div>
                    <div className={styles.steps}>
                        <p className={`${styles.step} ${step === 1 ? styles.active : ''}`}>1</p>
                        <div className={styles.line}></div>
                        <p className={`${styles.step} ${step === 2 ? styles.active : ''}`}>2</p>
                        <div className={styles.line}></div>
                        <p className={`${styles.step} ${step === 3 ? styles.active : ''}`}>3</p>
                    </div>
                    {
                        step >= 1 &&
                        <div style={{flexDirection: 'column', justifyContent: 'space-between', display: 'flex', flex: 1}}>
                            <div>
                                <div className={styles.inputWrap1}>
                                    <p className={styles.inputTitle}>{t("Create an account here")}</p>
                                    {/* <button className={styles.country}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                            <path d="M10 20C15.5229 20 20 15.5229 20 10C20 4.47715 15.5229 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5229 4.47715 20 10 20Z" fill="#F0F0F0" />
                                            <path d="M19.378 13.4783C19.78 12.395 20 11.2232 20 10C20 8.77683 19.78 7.6051 19.378 6.52179H0.621993C0.220039 7.6051 0 8.77683 0 10C0 11.2232 0.220039 12.395 0.621993 13.4783L10 14.3479L19.378 13.4783Z" fill="#0052B4" />
                                            <path d="M10.0001 20C14.2998 20 17.9652 17.2863 19.378 13.4783H0.62207C2.035 17.2863 5.70042 20 10.0001 20Z" fill="#D80027" />
                                        </svg>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                            <path d="M10 4L6 8L2 4" stroke="#899AA4" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </button>
                                    <span className={styles.line} /> */}
                                    {/* <input readOnly={step > 1} className={styles.phoneInput} onChange={(e) => {setPhoneNumber(e.target.value)}} type="text" placeholder='+90 xxx yyy zzz' /> */}
                                    <PhoneInput
                                        readOnly={step > 1}
                                        containerStyle={{width: '100%!important'}}
                                        inputStyle={{borderRadius: 100, paddingLeft: 50}}
                                        buttonStyle={{borderRadius: 100, border: 'none', background: 'transparent', margin: 5}}
                                        country={'tr'}
                                        onlyCountries={constants.allowedCountries}
                                        value={phoneNumber}
                                        placeholder={t("Enter your phone number")}
                                        onChange={phone => setPhoneNumber(phone)}
                                    />
                                </div>
                                <p className={styles.terms}><Trans>By clicking Continue, I accept the <Link onClick={() => onClose(false)} to="/user-agreements">User Agreement</Link> and <Link onClick={() => onClose(false)} to="/privacy">Privacy Policy</Link>.</Trans></p>
                            </div>
                            { step === 1 && (<button className={styles.login} onClick={onSendCode}>
                                {t("Send code")}
                                {loading && (
                                    <ColorRing
                                        visible={true}
                                        height="30"
                                        width="30"
                                        ariaLabel="blocks-loading"
                                        wrapperStyle={{}}
                                        wrapperClass="blocks-wrapper"
                                        colors={['#87BE02', '#87BE02', '#87BE02', '#87BE02', '#87BE02']}
                                    />
                                )}
                            </button>) }
                        </div>
                    }
                    {
                        step >= 2 &&
                        <>
                            <div className={styles.bigline}></div>
                            <p className={styles.step2Terms}><Trans>An SMS with a confirmation code has been sent to your phone number. Enter it in the field below and click 'Continue'</Trans></p>
                            <CodeInput resend={resend} setNaxtStep={onCheck} onReset={onReset} />
                            { iserror && step === 2 && (<p style={{width: '100%', textAlign: 'center', marginTop: '10px'}}>{t("Invalid Code!")}</p>) }
                            {
                                step < 3 && (
                                    timer > 0 && !iserror ?
                                    // eslint-disable-next-line
                                    <p className={styles.timerText}>{t("Resend in")} 00:{timer < 10 ? '0' + `${timer}` : timer}</p>
                                    : <button className={styles.login} onClick={onReSendCode}>{t("Send code")}</button>
                                )
                            }
                        </>
                    }
                    {
                        step >= 3 &&
                        <>
                            <div className={styles.bigline}></div>
                            <div className={styles.inputWrap}>
                                <input type="text" onChange={(e) => {setFullName(e.target.value)}} placeholder={t('Enter your name')} />
                            </div>
                            <div className={styles.inputWrap}>
                                <input type="text" onChange={(e) => {setSrName(e.target.value)}} placeholder={t('Surname')} />
                            </div>
                            <div className={styles.inputWrap}>
                                <input type={type} onChange={(e) => {setPassword(e.target.value)}} placeholder={t('Password')} />
                                <button onClick={() => setType(type === 'text' ? 'password' : 'text')} className={styles.showPassword}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <path d="M12.9833 9.99993C12.9833 11.6499 11.6499 12.9833 9.99993 12.9833C8.34993 12.9833 7.0166 11.6499 7.0166 9.99993C7.0166 8.34993 8.34993 7.0166 9.99993 7.0166C11.6499 7.0166 12.9833 8.34993 12.9833 9.99993Z" stroke="#3A3A3A" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M9.99987 16.8913C12.9415 16.8913 15.6832 15.1579 17.5915 12.1579C18.3415 10.9829 18.3415 9.00794 17.5915 7.83294C15.6832 4.83294 12.9415 3.09961 9.99987 3.09961C7.0582 3.09961 4.31654 4.83294 2.4082 7.83294C1.6582 9.00794 1.6582 10.9829 2.4082 12.1579C4.31654 15.1579 7.0582 16.8913 9.99987 16.8913Z" stroke="#3A3A3A" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </button>
                            </div>
                            <div className={styles.ruleContainer}>
                                <p className={styles.ulHeader}>{t("Password entry rules:")}</p>
                                <ul>
                                    <li className={styles.text}>{t("Minimum of 8 characters")}</li>
                                    <li className={styles.text}>{t("1 capital letter")}</li>
                                    <li className={styles.text}>{t("1 lowercase letter")}</li>
                                    <li className={styles.text}>{t("Minimum 1 digit")}</li>
                                </ul>
                            </div>
                            <div className={styles.inputWrap}>
                                <div className={styles.genders}>
                                    <div onClick={() => changeGender('man')} className={styles.gender}>
                                        <span className={`${currentGender === 'man' ? styles.active : ''}`}>
                                            <span className={`${currentGender === 'man' ? styles.activeCircle : ''}`}></span>
                                        </span>
                                        <p className={`${styles.genderTitle}`}>{t("Man")}</p>
                                    </div>
                                    <div onClick={() => changeGender('woman')} className={styles.gender}>
                                        <span className={`${currentGender === 'woman' ? styles.active : ''}`}>
                                            <span className={`${currentGender === 'woman' ? styles.activeCircle : ''}`}></span>
                                        </span>
                                        <p className={`${styles.genderTitle}`}>{t("Woman")}</p>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.inputWrap}>
                                <DatePickerComp startDate={startDate} setStartDate={setStartDate}/>
                            </div>
                            <button onClick={onRegister} ref={_ref} className={styles.next}>
                                {t("Next")}
                                {loading && (
                                    <ColorRing
                                        visible={true}
                                        height="30"
                                        width="30"
                                        ariaLabel="blocks-loading"
                                        wrapperStyle={{}}
                                        wrapperClass="blocks-wrapper"
                                        colors={['#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF']}
                                    />
                                )}
                            </button>
                        </>
                    }
                </div>
            </div>
        </div>
    );
}

export default RegistrationModal;