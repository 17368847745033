export default {
    "More than 800 services are<br/><2>waiting for you.</2>": "أكثر من 800<br/><2>خدمة في انتظارك</2>",
    "Nearby": "بالقرب من",
    "Find": "البحث",
    "Your safety and trust are our top priorities.": "سلامتك وثقتك هما أولويتان لدينا",
    "We help you make informed decisions, provide a rating and review system based on the experiences of other users.": "نساعدك في اتخاذ قرارات مستنيرة ونقدم نظام تقييم ومراجعة استنادًا إلى تجارب مستخدمين آخرين",
    "With BURABAK, you can experience the comfort of knowing that your service needs are in competent hands.": "مع BURABAK، يمكنك الاستمتاع بالراحة عند معرفة أن احتياجات خدمتك في أيدي مؤهلة",
    "At BURBAK, we are passionate about ": "في BURABAK، نحن",
    "transforming the way individuals and businesses access services. Our mission is to create a more sustainable and connected world by promoting resource sharing and community building.": "متحمسون لتحويل طريقة الأفراد والشركات في الوصول إلى الخدمات. مهمتنا هي خلق عالم أكثر استدامة وتواصلًا من خلال تعزيز مشاركة الموارد وبناء المجتمع",
    "We believe everyone should have easy access to the resources and expertise they need, regardless of traditional barriers.": "نعتقد أن الجميع يجب أن يكون لديهم وصول سهل إلى الموارد والخبرة التي يحتاجون إليها، بغض النظر عن الحواجز التقليدية",
    "With BURABAK, you can access a wide variety of services to suit your needs. If you need transportation, repair and maintenance, personal care or any other service, our platform will help you.": "مع BURABAK، يمكنك الوصول إلى مجموعة متنوعة من الخدمات تناسب احتياجاتك. إذا كنت بحاجة إلى وسائل نقل، أو صيانة وإصلاح، أو الرعاية الشخصية أو أي خدمة أخرى، ستساعدك منصتنا",
    "<0>Revolutionizing Service-Sharing</0><br/>for a Connected World.": "<0>ثورة في مشارك</0><br/> الخدمات لعالم متصل",
    "about us by numbers": "حولنا بالأرقام",
    "Professionals": "المحترفون",
    "Services": "الخدمات",
    "Customers": "العملاء",
    "Simplicity": "البساطة",
    "Our user-friendly platform allows you to connect with service providers in your area with just a few clicks.": "منصتنا سهلة الاستخدام تسمح لك بالاتصال بمقدمي الخدمة في منطقتك أو خارجها ببضع نقرات فقط",
    "Costs": "التكاليف",
    "We understand that cost is important, which is why our service providers offer competitive prices.": "نحن نفهم أهمية تكلفة الخدمات، ولهذا السبب تقدم مقدمو الخدمة لدينا أسعارًا تنافسية",
    "Location": "الموقع",
    "With our location-based search, it's easy to find someone nearby for help.": "باستخدام بحثنا القائم على الموقع، من السهل العثور على شخص قريب للمساعدة",
    "<0>User  </0>Agreement.": "<0>المستخدم  </0>اتفاق.",
    "Why we are the Best": "لماذا نحن الأفضل",
    "We are constantly improving our platform in line with your feedback and suggestions. Your contributions are invaluable in improving our services and making your experience even better.": "نحن نعمل باستمرار على تحسين منصتنا وفقًا لتعليقاتك واقتراحاتك. إسهاماتك لها قيمة كبيرة في تحسين خدماتنا وجعل تجربتك أفضل بكثير",
    "Our commitment to innovation drives us to explore new possibilities and bring you the best possible service sharing experience.": "التزامنا بالابتكار يدفعنا لاستكشاف إمكانيات جديدة وتقديم أفضل تجربة مشاركة خدمة ممكنة",
    "Get in touch": "اتصل بنا",
    "Contact form": "نموذج الاتصال",
    "Full name": "الاسم الكامل",
    "Enter your name": "أدخل أسمك",
    "Enter your full name": "أدخل اسمك الكامل",
    "Phone": "الهاتف",
    "E-mail": "البريد الإلكتروني",
    "Enter your E-mail": "أدخل بريدك الإلكتروني",
    "Message": "الرسالة",
    "Enter your message or question": "أدخل رسالتك أو سؤالك",
    "Send message": ". إرسال الرسالة",
    "INFO": "معلومات",
    "About us": "حولنا",
    "Contact us": "اتصل بنا",
    "How it work?": "كيف تعمل؟",
    "ADDITIONAL": "إضافي",
    "Support": "دعم",
    "Privacy Policy": "سياسة الخصوصية",
    "FAQ": "الأسئلة الشائعة",
    "User Agreement": "اتفاق المستخدم",
    "TO REACH US": "للتواصل معنا",
    "All Rights Reserved.": "جميع الحقوق محفوظة",
    "Sign In": "تسجيل الدخول",
    "Sign Up": "التسجيل",
    "Sign Out": "خروج",
    "How does this work": "كيف تعمل",
    "About": "عنا",
    "Login": "تسجيل الدخول",
    "Join us": "التسجيل",
    "What do you want?": "ماذا تحتاج؟",
    "Where?": "أين؟",
    "Write your needs in a few words": "اكتب احتياجاتك ببضع كلمات",
    "See experts on demand": "ابحث عن خبراء حسب الطلب",
    "Compare prices and choose": "قارن الأسعار واختر",
    "Join BURBAK today": "انضم إلى BURABAK اليوم",
    "Join BURABAK today and embark on a transformative journey. Experience the convenience, affordability, and sense of connection that service sharing brings. Together we can shape a brighter future where resources are shared, communities thrive and sustainable living is at hand.": "انضم إلى BURABAK اليوم وابدأ في رحلة تحولية. اختبر الراحة والتكلفة المعقولة وإحساس التواصل الذي يقدمه مشاركة الخدمات. معًا يمكننا صياغة مستقبل أفضل حيث تتم مشاركة الموارد وتزدهر المجتمعات ويتاح العيش المستدام",
    "Join": "انضم",
    "Free and without obligation.": "مجانًا وبدون التزام",
    "With BURABAK you will find the best experts in your field, whatever your needs.": "مع BURABAK، ستجد أفضل الخبراء في مجالك، مهما كانت احتياجاتك",
    "Register": "التسجيل",
    "More suitable in app": "مناسب أكثر في التطبيق",
    "Download": "تحميل",
    "Enter your phone number": "أدخل رقم هاتفك",
    "Password": "كلمة المرور",
    "Enter your password": "أدخل كلمة المرور الخاصة بك",
    "I forgot password": "لقد نسيت كلمة المرور",
    "Hide": "إخفاء",
    "Show": "عرض",
    "More": "المزيد",
    "Popular Categories": "الفئات الشهيرة",
    "Are you professional?": "هل أنت محترف؟",
    "BURABAK Your partner for finding new customers online": "BURABAK شريكك في العثور على عملاء جدد عبر الإنترنت",
    "Details": "التفاصيل",
    "An expert for every need": "خبير لكل احتياج",
    "By clicking Continue, I accept the <1>Membership Terms</1>.": "</1>بالنقر فوق متابعة، أقبل <1>شروط العضوية",
    "Send code": "إرسال الرمز",
    "An SMS with a confirmation code has been sent to your phone number. Enter it in the field below and click 'Continue'": "تم إرسال رسالة نصية قصيرة تحتوي على رمز تأكيد إلى رقم هاتفك. أدخله في الحقل أدناه وانقر على 'متابعة'",
    "Send SMS": "إرسال رسالة نصية قصيرة",
    "Surname": "اللقب",
    "Man": "رجل",
    "Woman" : "امرأة",
    "Next": "التالي",
    "<0>Privacy  </0>Policy.": "<0>Политика   </0>конфиденциальности.",
    "<0>HOW IT  </0><br/>Work?": "كيف تعمل؟",
    "welcome_professional": "هل أنت محترف؟ هل ترغب في وظيفة جزئية؟ هل تبحث عن وظيفة؟ إذاً نحن المكان المناسب. سجّل واملأ النموذج. على حسب تقديرك، ستتمكن من رؤية الطلبات أو طلبات العمل بالقرب منك أو في مدينة أخرى. يمكنك الرد والتفاوض وبدء العمل. ادفع فقط رسوم اشتراك شهرية أو سنوية رمزية واعمل عندما تريد أو اجلس في المنزل وابحث عن طلب",
    "welcome_customer": "نساعدك في اتخاذ قرارات مستنيرة ونقدم نظام تقييم وتعليقات استنادًا إلى تجربة مستخدمين آخرين. مع BURABAK، يمكنك أن تكون واثقًا من أن خدمتك في أيدي مؤهلة. فقط اترك طلبًا مع الشروط والأحكام وسيتصل أقرب محترف",
    "click to pay": "انقر للدفع",
    "include all system no extra<br/> payment needed": "نظام متكامل ولا حاجة<br/>  لدفعات إضافية",
    "Download our mobile application": "قم بتنزيل تطبيقنا المحمول",
    "Scan the QR code and download our mobile app": "امسح رمز الاستجابة السريعة وقم بتنزيل تطبيقنا المحمول",
    "Sneak peek1": "نظرة سريعة على روح مشروعنا",
    "Sneak peek2": "الميزات على استعداد لتألق، ستظهر قريبًا على الموقع",
    "Sneak peek5": "اغمر نفسك، وتعاون، واتبع، وشارك الأفكار معنا",
    "Sneak peek3": " معنا على موقعنا!",
    "Sneak peek4": "تواص",
    "OK": "موافق",
    "Create an account here": "أنشئ حسابًا هنا",
    "Invalid Code!": "رمز غير صالح",
    "Please Input Phone Number": "يرجى إدخال رقم الهاتف",
    "Please Input Password": "يرجى إدخال كلمة المرور",
    "Resend in": "إعادة إرسال في",
    "Password Length Must Be Greater Than 8.": "يجب أن تكون كلمة المرور أطول من 8 أحرف",
    "Please Check Your Network Status": "يرجى التحقق من حالة الشبكة الخاصة بك",
    "User Already Exists": "المستخدم موجود بالفعل",
    "Your phone number": "رقم هاتفك",
    "One more time": "مرة أخرى",
    "New Password": "كلمة المرور الجديدة",
    "Go": "انطلق",
    "User Not Exists": "المستخدم غير موجود",
    "Sign Up Success": "تم التسجيل بناجح",
    "Please confirm your password.": "يرجى تأكيد كلمة المرور الخاصة بك",
    "Success": "نجاح",
    "By clicking Continue, I accept the <1>User Agreement</1> and <3>Privacy Policy</3>.": "بالنقر فوق متابعة، أقبل اتفاق المستخدم وسياسة الخصوصية",
    "Please Input Full Name": "يرجى إدخال الاسم الكامل",
    "Please Input Sur Name": "يرجى إدخال اللقب",
    "Please Input Password One More Time": "يرجى إدخال كلمة المرور مرة أخرى",
    "House Cleaning": "تنظيف المنازل",
    "Office Cleaning": "تنظيف المكاتب",
    "After renovation": "بعد التجديد",
    "Deep cleaning": "تنظيف عميق",
    "Cleaning": "تنظيف",
    "Ceremony": "الحفلات",
    "Wedding halls": "قاعات الزفاف",
    "Musicans": "الموسيقيين",
    "Mourning halls": "قاعات العزاء",
    "Wedding cars": "سيارات الزفاف",
    "Legal": "قانوني",
    "Legal Advisor":"مستشار قانوني",
    "Corporate lawyer":"محامي شركات",
    "Real Estate":"العقارات",
    "Accounting":"المحاسبة",
    "Financial Advisor":"مستشار مالي",
    "Accountant":"محاسب",
    "Stock advisor":"مستشار الأسهم",
    "Health":"الصحة",
    "Nurses":"ممرضات",
    "Pharmacy":"صيدلية",
    "Emergency":"حالات الطوارئ",
    "Clinics":"العيادات",
    "Sport":"الرياضة",
    "GYM":"صالة الألعاب الرياضية",
    "Body-building":"بناء الأجسام",
    "Boxing":"الملاكمة",
    "Swiming pool":"السباحة",
    "Transport":"النقل",
    "Home to Home":"من باب إلى باب",
    'Office to Office':'من مكتب إلى مكتب',
    'In Country':'داخل البلاد',
    'Out of Country':'خارج البلاد',
    'Private lesson':'درس خصوصي',
    'Maths':'الرياضيات',
    'English':'الإنجليزية',
    'Germany':'الألمانية',
    'Spanish':'الإسبانية',
    'Repair':'الإصلاح',
    'Home':'المنزل',
    'Office':'المكتب',
    'Country house':'منزل الريف',
    'Building':'البناء',
    'Designer':'مصمم',
    'Planner':'مخطط',
    'Car repair':'إصلاح السيارات',
    'Maintenance':'الصيانة',
    'Tire service':'خدمة الإطارات',
    'Motor Mechanic':'ميكانيكي السيارات',
    'Spare parts':'قطع الغيار',
    'Restaurants':'المطاعم',
    'Fast Food':'الوجبات السريعة',
    'Bistro':'المقاهي',
    'Family':'العائلية',
    'Beauty': 'الجمال',
    'Barbarshop':'صالون الحلاقة',
    'Stylist':'مصفف الشعر',
    'Mail art':'فن الأظافر',
    'Photograph':'التصوير الفوتوغرافي',
    'Wedding':'حفلات الزفاف',
    'Spasial days':'الحفلات',
    'Birthday party':'حفلة عيد ميلاد',
    'Digital':'رقمي',
    'Developer':'مطور',
    'Mobile APP':'تطبيق الجوال',
    'Digital printing':'الطباعة الرقمية',
    'Warehouse':'المستودع',
    'Construction':'البناء',
    'Equipment':'المعدات',
    'Parking lot':'موقف السيارات',
    'Refrigerated':'تبريد',
    'Worker':'عامل',
    'Handyman':'حرفي',
    'Plasterer':'جص',
    'Builder':'بنّاء',
    'Loader':'حمال',
    'Heavy machine':'آلة ثقيلة',
    'Trackers':'جرارات',
    'Excavators':'حفارات',
    'Trailers':'مقطورات',
    "Customer":"العميل",
    "Executor":"المنفذ",
    "Leave an order with a task description.": "اترك طلبًا مع وصف المهمة",
    "Executers will write themselves. We will  Show to suitable executor. They will write if they are willing to help.":"المنفذون سيكتبون أنفسهم. سنعرضهم للمنفذ المناسب. سيكتبون إذا كانوا على استعداد للمساعدة",
    "Choose the right one, agree with him on all the details.":"اختر الشخص المناسب، واتفق معه على جميع التفاصيل",
    "Choose suitable task for you.":"اختر المهمة المناسبة لك",
    "Write if you are willing to help and write to customer.": "اكتب إلى العميل إذا كنت مستعدًا لقبول المهمة والمساعدة",
    "Agree on all the details with a customer.":"اتفاق على جميع التفاصيل مع العميل",
    "Signing...":"تسجيل الدخول ...",
    "Sign In Success": "تسجيل الدخول ناجح",
    "Welcome back":"مرحبًا مرة أخرى",
    "Password entry rules:":"قواعد إدخال كلمة المرور",
    "Minimum of 8 characters":"الحد الأدنى لعدد الأحرف هو 8",
    "1 capital letter":"حرف كبير واحد",
    "1 lowercase letter":"حرف صغير واحد",
    "Minimum 1 digit":"رقم واحد على الأقل",
    "Forgot Password?": "نسيت كلمة المرور؟",
    "Remember me": "تذكرني",
    "Please fill name field.": "يرجى ملء حقل الاسم",
    "Please fill phone number field.":"يرجى ملء حقل رقم الهاتف",
    "Please fill e-mail field.":"يرجى ملء حقل البريد الإلكتروني",
    "Please fill message field.":"يرجى ملء حقل الرسالة",

    // new words 30.09.2023
    "Home cleaning": "تنظيف المنزل",
    "Worker Plaster": "الجص العامل",
    "Load carrier": "حاملة الحمولة",
    "English teacher": "معلم لغة انجليزية",
    "Nurse": "ممرضة",
    "Crane": "رافعة",
    "Office cleaning": "تنظيف المكاتب",
    "Accounting consultant": "مستشار محاسبي",
    "Vegetarian Restaurant": "مطعم نباتي",
    "Lawyer Consultant": "مستشار قانوني",

    // advert texts
    "Adverts": "اعلانات",

    "<0>Download our</0><br/>Application.": "<0>تطبيقنا</0</0><br/>قم بتنز"
    ,
}