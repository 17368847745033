import FaqHeader from '../Components/FaqHeader';
import { useEffect } from 'react';
import { useTranslation, Trans } from "react-i18next";
import { ColorRing } from 'react-loader-spinner'
import useGetFaq from "../Hooks/useGetFAQ";

function Faq({onChangeLogin, onChangeRegistration}) {
    const [FAQ, getFAQ] = useGetFaq();
    const { t, i18n } = useTranslation();

    useEffect(() => {
        getFAQ(i18n.language);
    }, [i18n.language])

    useEffect(() => {
        window.scrollTo({top: 0, behavior: 'smooth'});
    }, [])

    return ( 
        <>
            <FaqHeader onChangeLogin={onChangeLogin} onChangeRegistration={onChangeRegistration}/>
            

            {FAQ === '' && (
                <div className='container' style={{minHeight: '50vh', padding: "80px 10px"}}>
                    <ColorRing
                        visible={true}
                        height="80"
                        width="80"
                        ariaLabel="blocks-loading"
                        wrapperStyle={{}}
                        wrapperClass="blocks-wrapper"
                        colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                    />
                </div>
            )}

            {FAQ !== '' && (
                <div className='container' style={{minHeight: '50vh', padding: "80px 10px"}} dangerouslySetInnerHTML={{__html: FAQ}}>
                    
                </div>
            )}
        </>
     );
}

export default Faq;