import Header from '../Components/Header'
import MobileBanner from '../Components/MobileBanner'
import Services from '../Components/Services'
import AdvertSection from '../Components/AdvertSection'
import PopularCategories from '../Components/PopularCategories'
import MainBanner from '../Components/MainBanner'
import HowWork from '../Components/HowWork'
import Professional from '../Components/Professional'
import { useEffect } from 'react'

function Main({toggleDownloadModalState, onChangeLogin, onChangeRegistration}) {

    useEffect(() => {
        window.scrollTo({top: 0, behavior: 'smooth'});
    }, [])

    return (
        <>
            <Header onChangeLogin={onChangeLogin} onChangeRegistration={onChangeRegistration}/>
            <MobileBanner />
            <Services />
            <AdvertSection />
            <PopularCategories />
            <MainBanner toggleDownloadModalState={toggleDownloadModalState} onChangeRegistration={onChangeRegistration}/>
            <HowWork value='home' />
            <Professional />
        </>
    );
}

export default Main;