import { useEffect } from "react";
import HeaderTop from "../Components/Header/HeaderTop";
import NewsFull from "../Components/NewsFull";
import NewsMminiTabs from "../Components/NewsMiniTabs";
import Professional from "../Components/Professional";

function News({onChangeLogin, onChangeRegistration}) {

    useEffect(() => {
        window.scrollTo({top: 0, behavior: 'smooth'});
    }, [])

    return ( 
        <>
            <HeaderTop viewType={'map'} onChangeLogin={onChangeLogin} onChangeRegistration={onChangeRegistration}/>
            <NewsMminiTabs/>
            <NewsFull/>
            <Professional/>
        </>
     );
}

export default News;