const apple_store = () => { return `https://apps.apple.com/tr/app/burabak/id6468394339` }
const google_store = () => { return `https://play.google.com/store/apps/details?id=com.burabak.burabak` }
const gallery_store = () => { return `https://appgallery.huawei.com/` }

const dev = false

const allowedCountries = [
    'at',
    'be',
    'bg',
    'hr',
    'cy',
    'cz',
    'dk',
    'ee',
    'fi',
    'fr',
    'de',
    'gr',
    'hu',
    'is',
    'ie',
    'it',
    'lv',
    'li',
    'lt',
    'lu',
    'mt',
    'nl',
    'no',
    'pl',
    'pt',
    'ro',
    'sk',
    'si',
    'es',
    'se',
    'al',
    'ad',
    'by',
    'ba',
    'ge',
    'xk',
    'mk',
    'md',
    'mc',
    'me',
    'sm',
    'rs',
    'ch',
    'tr',
    'gb',
    'ua',
    'az',
    'kg',
    'tj',
    'tm',
    'uz',
    'kz',
]

export default {
    base_url: dev ? 'http://localhost:8001' : 'https://api.burabak.com',
    google_store: google_store,
    apple_store: apple_store,
    gallery_store: gallery_store,

    allowedCountries: allowedCountries,

    showPreloaderModal: false
}