import styles from './styles.module.scss'
import { useState } from 'react';
import bg from '../../../assets/img/Subtract.png'
import ring from '../../../assets/img/ring.png'

import { useTranslation, Trans } from "react-i18next";

function DownloadModal({onClose}) {
    const [type, setType] = useState('password');
    const { t, i18n } = useTranslation();

    return ( 
        <div className={styles.modal}>
            <span className={styles.bg} onClick={() => onClose(true)} />

            <div className={styles.content}>      
            
                <img src={bg}  className={styles.pattern}/>          
                <div className={styles.card}>
                    <img src={ring} className={styles.ring} />
                    <div className={styles.textContainer}>
                        <p className={styles.hit}>{t('Sneak peek4')}</p>
                        <div className={styles.textContainerBody}>
                            <p className={styles.text1}>{t('Sneak peek3')}</p>
                            <div style={{position: 'absolute', top: '55px'}} className={styles.nnn}>
                                <p className={styles.text2}>{t('Sneak peek1')}</p>
                                <p className={styles.text3}>{t('Sneak peek2')}</p>
                                <p className={styles.text4}>{t('Sneak peek5')}</p>
                            </div>
                        </div>
                    </div> 
                </div>
                <button className={styles.OK} onClick={() => {
                    localStorage.setItem('preloader', 'true');
                    setTimeout(() => {
                        localStorage.setItem('preloader', 'false');
                    }, 1000 * 60 * 30)
                    onClose(false);
                }}>{t('OK')}</button>
            </div>
        </div>
     );
}

export default DownloadModal;